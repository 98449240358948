import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import "./ReadMatch.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player'

const Container = props => {
  const [playing, setPlaying] = useState(false);
  const [songPlaying, setSongPlaying] = useState('');
  const [dustbins, setDustbins] = useState([]);
  const [dustbinsAnswers, setDustbinsAnswers] = useState([]);


  const onDragEnd = async (result) => {
    const questionItem = dustbins.filter(i => Number(result.destination.droppableId) === i.id)[0];
    if (Number(result.destination.droppableId) === result.source.index) {
      playAudio(questionItem.sound, questionItem.sound_mp3);
      const destIndex = dustbins.indexOf(questionItem);
      questionItem.isEnabled = true;
      questionItem.en = questionItem.en.replace('/', '');
      dustbins[destIndex] = questionItem;;

      setDustbins(dustbins);
      setDustbinsAnswers(dustbinsAnswers.filter(i => result.source.index !== i.id))
    }
  }
  const onPlayingFalse = () => {
    setPlaying(false);
  }
  const playAudio = (sound, sound_mp3) => {
    let songPlaying = '';
    if (props.getBrowser() === 'Safari' || props.getOS() === 'iOS') {
      songPlaying = `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/audios/` + sound_mp3
    } else {
      songPlaying = `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/audios/` + sound
    }

    setSongPlaying(songPlaying);
    setPlaying(true);
  }

  useEffect(() => {
    let includelastDroppedItem = props.blockData.content.map(function (value, index) {
      value.isEnabled = false;
      value.lastDroppedItem = null;
      if (!value.shuffleOrder) {
        value.shuffleOrder = Math.floor(Math.random() * 100)
      }
      return {...value};
    });
    let filteredIncludelastDroppedItem = includelastDroppedItem.filter((arr, index) => {
      return arr.type !== "instruction";
    });
    setDustbins(filteredIncludelastDroppedItem);
    setDustbinsAnswers(filteredIncludelastDroppedItem);
  }, [props.blockData.content]);

  if(props.blockData.content[props.contentIndex].type === "instruction"){
    return (
      <div className="default-card">
        <div>
          <h3 className="instruction-title">
            Instruction
          </h3>
          <h5 className="instruction-text">
            {props.blockData.content[props.contentIndex].instruction}
          </h5>
        </div>
      </div>
    )
  }

  return (

    <DragDropContext
      className="container"
      onDragEnd={onDragEnd}
    >
      <ReactPlayer
        url={songPlaying}
        width={0}
        height={0}
        onEnded={onPlayingFalse}
        playing={playing}
      />
      <div className="cardsContainer">
        {dustbins.map(({ id, en, sound, sound_mp3, isEnabled }, index) => (

          <Droppable
            droppableId={`${id}`}
            key={`droppable-question-${index}`}
          >
            {(provided, snapshot) => (
              <div>
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="dustbinCard"
                  style={{
                    backgroundColor: snapshot.isDraggingOver ? "lightblue" : "white"
                  }}
                >
                  {en.split('/')[0]}
                </div>
                {isEnabled && (
                  <div id={`audio-${id}`} className="audio-container" onClick={() => {
                    playAudio(`${sound}`, `${sound_mp3}`);
                  }}>
                    <div className="audio-icon" style={readMatchStyles.boxIcon}>
                      <FontAwesomeIcon className="icon-step" icon={faVolumeUp} style={readMatchStyles.iconWidth} />
                    </div>
                  </div>
                )}
                {!isEnabled && (
                  <div id={`off-${id}`} className="audio-container">
                    <div className="audio-icon" style={readMatchStyles.boxIconOff} >
                      <FontAwesomeIcon className="icon-step" icon={faVolumeUp} style={readMatchStyles.iconWidth} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </Droppable>
        ))}

      </div>
      <hr />
      <Droppable
        droppableId="droppable-answers"
        key="droppable-answers"
      >
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="cardsContainer"
          >
            {provided.placeholder}
            {dustbinsAnswers.map(({ id, en, shuffleOrder }, index) => {
              return <Draggable
                key={index}
                draggableId={`draggable-${index}`}
                index={id}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="dustbinCard"
                    style={{
                      order: shuffleOrder,
                      backgroundColor: snapshot.isDragging ? "lightblue" : "white",
                      ...provided.draggableProps.style
                    }}
                  >
                    {en.split('/')[1] || "Resposta não cadastrada, favor contactar o administrador"}
                  </div>
                )}
              </Draggable>
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext >


  );
};
export default Container;

const readMatchStyles = {
  playOff: {
    'backgroundColor': '#CCC',
  },
  iconWidth: {
    'fontSize': '15px',
  },
  boxIcon: {
    'width': '30px',
    'height': '30px',
  },
  boxIconOff: {
    'width': '30px',
    'height': '30px',
    'backgroundColor': '#CCC'
  }
};