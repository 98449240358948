import React from "react";
import { DropTarget } from "react-dnd";
export const Dustbin = ({
  getPicture,
  isOver,
  canDrop,
  connectDropTarget,
  lastDroppedItem
}) => {
  let backgroundColor = "white";
  if (lastDroppedItem) {
    backgroundColor = "#003057";
  }

  return connectDropTarget(
    <div className="dustbin-match" style={{ backgroundColor }}>
      <div style={{ flex: 1 }}>
        <img
          src={`${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/${getPicture}`}
          className="dustbin-match-img"
          alt="Dust-bin"
        />
      </div>
    </div>
  );
};
export default DropTarget(
  props => props.accepts,
  {
    drop(props, monitor) {
      props.onDrop(monitor.getItem());
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(Dustbin);
