import React, { useState, useRef, useEffect, useCallback } from "react";
import { FaMicrophone, FaStop } from "react-icons/fa";
import { BsSendFill } from "react-icons/bs";

import {
  getScoreDataSpeechace,
  getScoreMetricsSpeechace,
  sendDataSpeechaceMetrics,
  updateScoreMetricsSpeechace,
} from "../service/service";
import "../assets/templates/MicrophoneComponent.css";

const MicrophoneComponent = ({
  data,
  contentIndex,
  callbackResponseScore,
  callbackExercisesRecordingAttempts,
  callbackAudioVrRecordingAttempts,
  callbackAudioVrSetValueContentIndex,
  callbackAudioVrSetRecordingAttempts,
}) => {
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioBlobUrl, setAudioBlobUrl] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);
  const [valueContentIndex, setValueContentIndex] = useState(0);
  const [erroHandlingScore, setErroHandlingScore] = useState(null);
  const [recordingAttempts, setRecordingAttempts] = useState(0);
  const [hasMetricsCurrentExercise, setHasMetricsCurrentExercise] =
    useState(false);
  const [dataMetricsCurrentExercise, setDataMetricsCurrentExercise] =
    useState(null);
  const [countEngagementTime, setCountEngagementTime] = useState(0);
  const [manipulatedEngagementIndex, setManipulatedEngagementIndex] =
    useState(contentIndex);

  const mediaRecorderRef = useRef();
  const audioElementRef = useRef();

  const userInfo = JSON.parse(localStorage.getItem("user-info"));

  const text = data.en;

  const fetchData = useCallback(async () => {
    try {
      const res = await getScoreMetricsSpeechace(userInfo.id, data.id)
        .then((res) => res)
        .catch((err) => err);

      

      if (res.response && res.response.status === 404) {
        setRecordingAttempts(0);
        setHasMetricsCurrentExercise(false);
        callbackAudioVrSetValueContentIndex(contentIndex);
        callbackAudioVrSetRecordingAttempts(0);
        callbackExercisesRecordingAttempts(0);
      } else if (res && res.data.id) {
        setRecordingAttempts(res.data.number_attempts);
        setHasMetricsCurrentExercise(true);
        setDataMetricsCurrentExercise(res.data);
        callbackAudioVrSetValueContentIndex(contentIndex);
        callbackExercisesRecordingAttempts(res.data.number_attempts);
      }
    } catch (error) {
      console.error("Erro ao buscar métricas:", error);
    }
  }, [
    data.id,
    userInfo.id,
    callbackAudioVrSetValueContentIndex,
    contentIndex,
    callbackAudioVrSetRecordingAttempts,
    callbackExercisesRecordingAttempts,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData, recordingAttempts]);

  useEffect(() => {
    if (recordingAttempts > 0) {
      callbackAudioVrRecordingAttempts(recordingAttempts);
      callbackExercisesRecordingAttempts(recordingAttempts);
    }
  }, [
    callbackAudioVrRecordingAttempts,
    callbackExercisesRecordingAttempts,
    contentIndex,
    recordingAttempts,
    valueContentIndex,
  ]);

  useEffect(() => {
    if (contentIndex !== manipulatedEngagementIndex) {
      setCountEngagementTime(0);
      setManipulatedEngagementIndex(contentIndex);
    }

    const intervalId = setInterval(() => {
      setCountEngagementTime(countEngagementTime + 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [contentIndex, countEngagementTime, manipulatedEngagementIndex]);

  const handleScoreDataSpeechace = async (audio) => {
    try {
      setIsLoading(true);
      setErroHandlingScore(false);

      const formData = new FormData();
      const score_type =
        data.section === "Unlock" ? "scoring/speech" : "scoring/text";

      formData.append("user_id", userInfo.id);
      formData.append("text", text);
      formData.append("score_type", score_type);
      formData.append("audio", audio);

      const response = await getScoreDataSpeechace(formData);
      const { data: dataResponse } = response;

      if (!hasMetricsCurrentExercise) {
        handleMetricsForNoPreviousAttempts(response, dataResponse);
      } else {
        handleMetricsForPreviousAttempts(response, dataResponse);
      }
    } catch (error) {
      handleScoreDataError(error);
    }
  };

  const handleMetricsForNoPreviousAttempts = async (response, dataResponse) => {
    if (dataResponse.status_code === 200) {
      callbackResponseScore(response);
      setRecordingAttempts((prevAttempts) => prevAttempts + 1);

      const dataMetrics = {
        user_id: userInfo.id,
        username: userInfo.username,
        book_name: data.book_name,
        id_exercise_question: data.id,
        phrase: text,
        number_attempts: recordingAttempts + 1,
        first_attempts_percentage:
          recordingAttempts === 0
            ? Number(dataResponse.result.overall_score)
            : 0,
        second_attempts_percentage:
          recordingAttempts === 1
            ? Number(dataResponse.result.overall_score)
            : 0,
        third_attempts_percentage:
          recordingAttempts === 2
            ? Number(dataResponse.result.overall_score)
            : 0,
        engagement_time: Number(countEngagementTime),
        first_engagement_time_with_speechace: Number(
          dataResponse.audio_duration_seconds
        ),
        second_engagement_time_with_speechace:
          recordingAttempts === 1
            ? Number(dataResponse.audio_duration_seconds)
            : 0,
        third_engagement_time_with_speechace:
          recordingAttempts === 2
            ? Number(dataResponse.audio_duration_seconds)
            : 0,
      };

      await sendDataSpeechaceMetrics(dataMetrics);
      setIsLoading(false);
      setCountEngagementTime(0);
    }
  };

  const handleMetricsForPreviousAttempts = async (response, dataResponse) => {
    if (dataResponse.status_code === 200) {
      callbackResponseScore(response);
      setRecordingAttempts((prevAttempts) => prevAttempts + 1);

      const dataMetrics = {
        id: dataMetricsCurrentExercise.id,
        user_id: userInfo.id,
        username: userInfo.username,
        book_name: data.book_name,
        id_exercise_question: data.id,
        phrase: text,
        number_attempts: dataMetricsCurrentExercise.number_attempts + 1,
        first_attempts_percentage:
          dataMetricsCurrentExercise.first_attempts_percentage,
        second_attempts_percentage:
          recordingAttempts === 1
            ? dataResponse.result.overall_score
            : dataMetricsCurrentExercise.second_attempts_percentage,
        third_attempts_percentage:
          recordingAttempts === 2
            ? dataResponse.result.overall_score
            : dataMetricsCurrentExercise.third_attempts_percentage,
        engagement_time: Number(countEngagementTime),
        first_engagement_time_with_speechace:
          dataMetricsCurrentExercise.first_engagement_time_with_speechace,
        second_engagement_time_with_speechace:
          recordingAttempts === 1
            ? Number(dataResponse.audio_duration_seconds)
            : dataMetricsCurrentExercise.second_engagement_time_with_speechace,
        third_engagement_time_with_speechace:
          recordingAttempts === 2
            ? Number(dataResponse.audio_duration_seconds)
            : 0,
      };

      await updateScoreMetricsSpeechace(dataMetrics);
      setIsLoading(false);
      setCountEngagementTime(0);
    }
  };

  const handleScoreDataError = (error) => {
    setIsLoading(false);
    setErroHandlingScore(true);
    setAudioBlob(null);
    console.error("Erro ao enviar áudio para a API:", error);
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      const audioChunks = [];

      setAudioBlob(null);

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.push(event.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        setAudioBlob(audioBlob);
        setAudioBlobUrl(URL.createObjectURL(audioBlob));

        const audioElement = new Audio(URL.createObjectURL(audioBlob));

        audioElement.addEventListener("loadedmetadata", async () => {
          audioElement.currentTime = 1e100;
          audioElement.ondurationchange = () => {
            const audioDurationInSeconds = audioElement.duration;
            setAudioDuration(audioDurationInSeconds);
          };
        });
      };

      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;
      setRecording(true);
      setValueContentIndex(contentIndex);
      setManipulatedEngagementIndex(contentIndex);
    } catch (error) {
      console.error("Erro ao acessar o microfone:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      setAudioDuration(0);
      setIsPlaying(false);
    }
  };

  const handleAudioPlay = () => {
    audioElementRef.current.play();
    setIsPlaying(true);
  };

  const handleAudioPause = () => {
    audioElementRef.current.pause();
    setIsPlaying(false);
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className="microphone-container">
      {isLoading ? (
        <div className="loading-indicator">Carregando...</div>
      ) : (
        <>
          {recordingAttempts < 3 && (
            <div className="container-button">
              <button
                onClick={recording ? stopRecording : startRecording}
                className={recording ? "stop-button" : "start-button"}
              >
                {recording ? <FaStop /> : <FaMicrophone />}
              </button>
              <span>2. Record your answer</span>
            </div>
          )}

          {erroHandlingScore === true && (
            <p>No speech is detected, please try again!</p>
          )}

          {audioBlob && (
            <>
              <div className="audio-player">
                {audioDuration <= 15 ? (
                  valueContentIndex === contentIndex && (
                    <>
                      <div className="custom-audio-player">
                        <div
                          onClick={() => {
                            if (isPlaying) {
                              handleAudioPause();
                            } else {
                              handleAudioPlay();
                            }
                          }}
                        >
                          {isPlaying ? (
                            <div className="pause-icon stop-button">||</div>
                          ) : (
                            <div className="play-icon start-button">▶</div>
                          )}
                        </div>
                        <audio
                          ref={audioElementRef}
                          src={audioBlobUrl}
                          onEnded={handleAudioEnded}
                        />
                      </div>
                      <span>3. Listen to your answer</span>
                    </>
                  )
                ) : (
                  <p>Grave novamente. Duração superior a 15 segundos.</p>
                )}
              </div>

              {recordingAttempts < 3 && valueContentIndex === contentIndex && (
                <div className="container-button">
                  <button
                    onClick={() => handleScoreDataSpeechace(audioBlob)}
                    className="send-button"
                  >
                    <BsSendFill />
                  </button>
                  <span>4. Submit your answer</span>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MicrophoneComponent;
