import React, { useState, useCallback, useEffect } from "react";
import Dustbin from "../components/DustbinMatch";
import Box from "../components/BoxMatch";
import update from "immutability-helper";
import ReactPlayer from 'react-player'

const Container = props => {
  const data = props.blockData.content.filter(e => !e.instruction);

  let getData = data.map(function (arr) {
    arr.lastDroppedItem = null;
    return arr;
  });

  const [dustbins, setDustbins] = useState(getData);
  const [playing, setPlaying] = useState(false);
  const [boxes, setBoxes] = useState(getData);

  function isEquivalent(a, b) {
    // Acredito que essa seja uma das qualidades do código.
    // É realizada a verificação do que o objeto possui internamente, e isso
    // é passado diretamente para a variável.
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // Então, é feita a verificação se os dois objetos possuem o mesmo
    // número de propriedades. Muito bom! E tira muito trabalho caso não
    // tenham.
    if (aProps.length !== bProps.length) {
      return false;
    }

    // Caso tenham o mesmo número, é realizada uma iteração por todas as
    // propriedades do objeto. Porém, como ressaltei antes, não busca por
    // objetos dentro de objetos, podendo retornar uma inconsistência.
    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    return true;
  }

  if (!isEquivalent(boxes, getData)) {
    setDustbins(getData);
    setBoxes(getData);
  }

  const [droppedBoxNames, setDroppedBoxNames] = useState([]);
  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }

  const handleDrop = useCallback(
    (index, item) => {
      const { name } = item;
      let songPlaying = ''
      if (props.getBrowser() === 'Safari' || props.getOS() === 'iOS') {
        songPlaying = `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/audios/${dustbins[index].sound_mp3}`
      } else {
        songPlaying = `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/audios/${dustbins[index].sound}`
      }
      setSongPlaying(songPlaying)
      setPlaying(true)
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] })
      );
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item
            }
          }
        })
      );
    },
    [droppedBoxNames, dustbins]
  );

  const getShuffledArr = arr => {
    const newArr = arr.slice()
    var i = 0;
    var cont = 0;
    while (newArr) {
      if (typeof newArr[i] == 'undefined') break;
      if (!droppedBoxNames.includes(newArr[i]['en'])) {
        const rand = Math.floor(Math.random() * (cont + 1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
        newArr[rand]['index'] = i;
        cont++;
      }
      i++;
    }
    return newArr
  };

  const [boxesRamdom, setBoxRamdom] = useState([]);
  useEffect(function () {
    const ramdom = getShuffledArr(boxes);
    setBoxRamdom(ramdom);
  }, [boxes, dustbins]);

  const [songPlaying, setSongPlaying] = useState('');
  const onPlayingFalse = () => {
    setSongPlaying('')
    setPlaying(false)
  }

  const { type, instruction } = props.blockData.content[props.contentIndex];
  return (
    <div className="default-card container">
      <ReactPlayer
        url={songPlaying}
        width={0}
        height={0}
        onEnded={onPlayingFalse}
        playing={playing}
      />
      {
        instruction ?
          <div>
            <h3 className="instruction-title">Instruction</h3>
            <h5 className="instruction-text">{instruction}</h5>
          </div>
          :
          <div className="match-container">
            <div
              align="center"
              className="row match-image-item"
            >
              {dustbins.map(({ type, en, picture, lastDroppedItem }, index) => (
                <div className="col-md-2 match-image-item" key={index}>
                  <Dustbin
                    accepts={en}
                    lastDroppedItem={lastDroppedItem}
                    getPicture={picture}
                    name={en}
                    onDrop={item => handleDrop(index, item)}
                    key={index}
                  />
                </div>
              ))}
            </div>
            <div
              align="center"
              className="row"
              style={{ justifyContent: 'center' }}
            >
              {boxesRamdom.map(({ en, type, index }) => (
                <div className="col-md-2 text-center match-text-item" key={index}>
                  <Box name={en} type={en} isDropped={isDropped(en)} key={index} />
                </div>
              ))}
            </div>
          </div>
      }
    </div>
  );
};
export default Container;
