import React, { Component } from "react";
import "../assets/templates/LeadInText.css";

class LeadInText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      borderColorBool: false,
      data: [],
      buttonMsg: 'Check'
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.blockData !== prevProps.blockData) {
      this.getwords();
    }
  }

  componentDidMount() {
    this.getwords();
  }

  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  getwords() {
    let dataYes = this.props.blockData.complement.yes;
    let dataNo = this.props.blockData.complement.no;
    let arrYes = [];
    let arrNo = [];
    let concatObj = [];
    for (let i = 0; i < dataYes.length; i++) {
      arrYes.push({
        value: dataYes[i],
        status: "yes",
        originalColor: "green",
        selectedColor: "black",
        clicked: false
      });
    }
    for (let j = 0; j < dataNo.length; j++) {
      arrNo.push({
        value: dataNo[j],
        status: "no",
        originalColor: "red",
        selectedColor: "black",
        clicked: false
      });
    }
    concatObj = arrNo.concat(arrYes);
    this.setState({ data: this.shuffle(concatObj), buttonMsg: 'Check' });
  }

  changeColor(item) {
    let newData = this.state.data;
    newData.forEach(function (value) {
      if (item === value.value) {
        value.clicked = !value.clicked;
      }
    });

    this.setState({ data: newData });
  }

  _checkOrder() {
    let data = this.state.data;
    data.forEach(function (value) {
      value.selectedColor = value.originalColor
      value.clicked = true;
    });

    this.setState({ data: data, buttonMsg: 'Done' });
    if (this.state.buttonMsg === 'Done') {
      this.getwords()
      this.props.step('forward')
    }
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card container">
        {blockData.content[contentIndex].type === "instruction" ? (
          <div>
            <h3
              onChange={() => console.log("h3 change")}
              className="instruction-title"
            >
              Instruction
            </h3>
            <h5 className="instruction-text">
              {blockData.content[contentIndex].instruction}
            </h5>
          </div>
        ) : (
          <div>
            <div
              className="listen-drag-check-maze"
              onClick={() => {
                this._checkOrder();
              }}
            >
              {this.state.buttonMsg}
            </div>
            <div className="alignItens">
              <img
                alt="LeadInText"
                src={`${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/${blockData.complement.image.img}`}
                className="leadInTextImg"
                style={{
                  maxHeight: "300px",
                  maxWidth: "300px",
                }}
              />
            </div>
            <div className="row">
              {this.state.data.map((item, i) => (
                <div className="col-md-2" style={{ marginTop: "10px", marginLeft: 'auto', marginRight: 'auto' }} key={i}>
                  <button
                    className="btn btn-default randButton"
                    onClick={() => {
                      if (this.state.buttonMsg === 'Check') this.changeColor(item.value);
                    }}
                    style={{
                      border: "solid 3px",
                      borderColor: item.clicked ? item.selectedColor : "white",
                      minWidth: '100px'
                    }}
                  >{`${item.value}`}</button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LeadInText;
