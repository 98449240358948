import React, { Component } from "react";
import '../assets/templates/EndLesson.css';
import lessonEnd from '../assets/images/lesson-end.png'
import { Link } from "react-router-dom";
import { endLesson } from '../service/service'
import ReactGA from 'react-ga';

class EndLesson extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  _getCurrentDate() {
    let currentdate = new Date();
    let datetime = currentdate.getFullYear() + "-"
      + (((currentdate.getMonth() + 1) < 10) ? "0" : "") + (currentdate.getMonth() + 1) + "-"
      + ((currentdate.getDate() < 10) ? "0" : "") + currentdate.getDate() + " "
      + ((currentdate.getHours() < 10) ? "0" : "") + currentdate.getHours() + ":"
      + ((currentdate.getMinutes() < 10) ? "0" : "") + currentdate.getMinutes() + ":"
      + ((currentdate.getSeconds() < 10) ? "0" : "") + currentdate.getSeconds();

    return datetime
  }

  _endLesson() {
    let { lessonId, catchupId } = this.props



    let data = {
      catchUPID: catchupId,
      licaoID: lessonId,
      dataHora: this._getCurrentDate()
    }

    console.log('data: ', data)

    endLesson(data).then(res => {
      console.log('success: ', res)
    }).catch(err => {
      console.log('error: ', err)
    })
  }

  componentDidMount() {
    // retirar funcao durante corona
    //   this._endLesson()
    let { lessonId } = this.props
    const trackingId = 'UA-1839981-59'
    ReactGA.initialize(trackingId)

    ReactGA.event({
      category: "Catchup",
      action: "Ended Lesson",
      label: lessonId.toString()
    })
  }


  render() {
    return (
      <div className="default-card workbook-container">
        <div>
          <img alt="WorkBook" className="workbook-img" src={lessonEnd} />
        </div>
        <div style={{ marginTop: 15 }}>
          <h2> Continue Praticando! </h2>
        </div>
        <div style={{ fontWeight: 'lighter', width: '50%', marginTop: 10, marginBottom: 15 }}>
          Agora que você terminou, aproveite o restante da sua aula para continuar fazendo mais exercícios no Wiz.me.
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ marginRight: 30 }} className="workbook-button">
            <Link
              to={`/classes/list`}>
              Voltar para lessons
            </Link>
          </div>
          <div className="workbook-button" onClick={() => { window.open('https://me.wizard.com.br', '_blank') }}>
            Acessar online exercises
          </div>
        </div>
      </div>
    )
  }
}

export default EndLesson
