
const sendDataLayerEvent = (eventName) => {
    const user = JSON.parse(localStorage.getItem('user-info')) || ''
    window.dataLayer = window.dataLayer || [];
    
    window.dataLayer.push({
        'event': eventName,
        'userId': user.id
    })

}

export default sendDataLayerEvent