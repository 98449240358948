import React, { useEffect } from "react";
import { Route, Redirect, HashRouter } from "react-router-dom";
import cookie from 'react-cookies'

import Login from "./containers/login";
import Exercises from "./containers/exercises";
import Home from "./containers/home";
import LtiLaunchCallback from "./containers/ltiLaunchCallback";


function checkLoginStatus(){
  let token = cookie.load('token_info')
  return !(token === null)
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      checkLoginStatus() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
)

const sendDataLayer = () => {
  const user = JSON.parse(localStorage.getItem('user-info')) || ''
  
  window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
    'userId': user.id
    })
}


function App() {

  useEffect(() => {
    sendDataLayer()
  }, [])

  return (
    <div className="App">
      <HashRouter basename="catchup">
        <Route path="/" exact component={()=>{ 
          return (
            <div class="login-redirect"><h1>Use o seguinte o link <a href="https://me.wizard.com.br/catchup">https://me.wizard.com.br/catchup</a> para as suas atividades do Catchup.</h1></div>
          )
        }} />
        <Route path="/login-pearson" exact component={Login} />
        <Route path="/lti_launch_callback" component={LtiLaunchCallback} />
        <PrivateRoute path="/classes/list" component={Home} />
        <PrivateRoute path="/protected" component={()=>{ return (<h1> Protected </h1>) }} />
        <PrivateRoute path="/catchupId/:catchupId/lesson/:lessonId/:block" component={Exercises} />
      </HashRouter>   
    </div>
  );
}


export default App;
