import React, { Component } from "react";
import '../assets/templates/SimpleTextHL.css';

class SpeakRightDouble extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      index: 0,
      divPositions: []
    }
  }

  SpeakItemImpar(id) {
    const { blockData } = this.props
    
    let itemImpar = blockData.content.filter((x) => { return x.id === id + 1 })

    return itemImpar[0].en

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contentIndex !== this.props.contentIndex) {
      this.setState({
        open: false,
      })
    }
  }

  _reset() {
    this.setState({
      open: false
    })
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card">
        <div style={{ width: '90%' }}>
          {
            blockData.content[contentIndex].type === 'instruction' ?
              <div>
                <h3 onChange={() => console.log('h3 change')} className="instruction-title">Instruction</h3>
                <h5 className="instruction-text">{blockData.content[contentIndex].instruction}</h5>
              </div>
              :



              <div style={{ width: '100%' }}>
                {

                  blockData.content.filter((x) => { return x.type !== "instruction" && x.id % 2 === 0 }).map((item, index) => {
                    return (
                        <div
                          ref={(ref) => {
                            // scroll to the current div position
                            if (ref && item.id  === blockData.content[contentIndex].id) { window.scrollTo(0, ref.offsetTop - (window.innerHeight / 2)) }
                          }}
                          key={index} className={"speak-right-single-container " + (item.id === blockData.content[contentIndex].id || item.id+1 === blockData.content[contentIndex].id? "simple-active" : "")} 
                          
                          style={{textAlign:"center"}}>

                            

                          <h2 style={{flex:1, color: "white" }}>
                            {
                              item.en 
                           }
                          </h2>

                          <h2 style={{flex:1, color: "white" }}>
                            {
                              this.SpeakItemImpar(item.id)
                           }
                          </h2>
                         
 

                        </div>
                        
                        
                    )
                  })
                }
              </div>

          }
        </div>
      </div>
    )
  }
}

export default SpeakRightDouble
