import React, { Component } from "react";
import '../assets/templates/SimpleSentence.css';

class SimpleSentence extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      openSecond: false,
      index: 0
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contentIndex !== this.props.contentIndex) {
      this.setState({
        open: false,
      })
    }
  }

  _reset() {
    this.setState({
      open: false
    })
  }

  showContent(type) {
    const { data } = this.props
    if (data && data.type) {
      switch (type) {
        case 'instruction':
          return (
            <div>
              <h3 onChange={() => console.log('h3 change')} className="instruction-title">Instruction</h3>
              <h5 className="instruction-text">{data.instruction}</h5>
            </div>
          )

        default:
          return (
            <h2>
              <div className="simple-sentence-container" >
                {data.pt.length > 0 && data.pt !== "#"
                  ? data.pt
                  : data.en
                }
              </div>
            </h2>
          )

      }
    }
  }

  render() {
    const { data } = this.props;
    if (data && data.type) {
      return (
        <div className="default-card">
          {
            this.showContent(data.type)
          }

        </div>
      )
    }
    else{
      return <div className="default-card">Template não configurado</div>
    }
  }
}

export default SimpleSentence;
