import React, { Component } from "react";
import '../assets/templates/WordsInContext.css';

class WordsInContext extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      index: 0
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.contentIndex !== this.props.contentIndex){
      this.setState({
        open: false,
      })
    }
  }

  _reset(){
    this.setState({
      open: false
    })
  }

  getHilighted(text){
    let splitText = text.split(/( )/)
    let textObj = []
    for(let i = 0; i < splitText.length; i++){
        if(splitText[i].includes("[") || splitText[i].includes("]") ){
            textObj.push({word: splitText[i].replace("[","").replace("]",""), highLight: true})
        }else{
            textObj.push({word: splitText[i], highLight: false})
        }
    }
    console.log('textObj: ', textObj)
    return textObj
  }

  render() {
    const { data, double } = this.props;
    return (
      <div className="default-card">
        <div style={{width: '100%'}}>
            {
                data.type === 'instruction' ?
                <div>
                    <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                    <h5 className="instruction-text">{ data.instruction }</h5>
                </div>
                :
                <div>   
                    {
                        // check if the template is single OR double type
                        double ?
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        { data.en.split("/").slice(0,2).map((cont) => {
                            return(
                                <div className="words-context-container">
                                    <h2>
                                        { cont }
                                    </h2>
                                </div>
                            )
                        }) }
                        </div>
                        :
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        { data.en.split("/")[0].split(",").slice(0,3).map((cont) => {
                            return(
                                <div className="words-context-container">
                                    <h2>
                                        { cont }
                                    </h2>
                                </div>
                            )
                        }) }
                        </div>
                    }
                    {
                        data.pt.length > 0 ?
                        <div 
                        className="phrase-context-container">
                            <h2>
                                {data.pt}
                            </h2>
                        </div>
                        :
                        <div 
                        className="phrase-context-container">
                            {  
                                this.getHilighted(data.en.replace(/(\/)/g,"")).slice(3).map((item, wordIndex)=>{
                                    return(
                                        <h2 key={wordIndex} style={{ 
                                            color: item.highLight ? 'rgb(47, 157, 201)' : 'white',
                                            marginRight: 3
                                            }}>
                                            { item.word }
                                        </h2>
                                    )
                                }) 
                            }
                        </div>
                    }
                </div>
                
            }
        </div>
      </div>
    )
  }
}

export default WordsInContext;
