import React, { Component } from "react";
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import { isMobile } from 'react-device-detect';
import HTML5Backend from 'react-dnd-html5-backend'
import Header from "../components/header";
import WizTabLeftNav from "../components/leftNav";
import DoubleSentence from "../templates/DoubleSentence"
import IrregularVerbsTestYourself from "../templates/IrregularVerbsTestYourself"
import Mosaic from "../templates/Mosaic"
import DoubleSentenceHL from "../templates/DoubleSentenceHL"
import TextPagesHL from "../templates/TextPagesHL"
import SimpleTextHL from "../templates/SimpleTextHL"
import SpeakRightSingle from "../templates/SpeakRightSingle"
import SpeakRightDouble from "../templates/SpeakRightDouble"
import SimpleSentence from "../templates/SimpleSentence"
import SimpleSentenceVr from "../templates/SimpleSentenceVr";

import SingleCard from "../templates/SingleCard"
import Footer from "../components/footer"
import LinkingSounds from "../templates/LinkingSounds"
import { getPrePostVideo, getLessonContent, createUsage, updateUsage, getUsage } from '../service/service';
import IrregularVerbs from "../templates/IrregularVerbs";
import IrregularVerbs3 from "../templates/IrregularVerbs3";
import WordsInContext from "../templates/WordsInContext";
import ConversationInTrio from "../templates/ConversationInTrio";
import WebTalk from "../templates/WebTalk";
import ConversationInTwo from "../templates/ConversationInTwo";
import TextIllustrated from "../templates/TextIllustrated";
import FluencyInPairs from "../templates/FluencyInPairs";
import Carousel from "../templates/Carousel";
import ImageAudio from "../templates/ImageAudio";
import TextPagesIllustrated from "../templates/TextPagesIllustrated";
import ReadMatch from "../templates/ReadMatch";
import ListenMatch from "../templates/ListenMatch";
import Match from "../templates/Match";
import PrePost from "../templates/PrePost";
import Audio from "../templates/Audio";
import TextPagesScrambled from "../templates/TextPagesScrambled";
import Workbook from "../templates/Workbook";
import DialogRolePlay from "../templates/DialogRolePlay";
import ListenDrag from "../templates/ListenDrag";
import Song from "../templates/Song";
import Maze from "../templates/Maze";
import EndLesson from "../templates/EndLesson";
import ImageSubtitle from "../templates/ImageSubtitle";
import DoubleSentenceStatic from "../templates/DoubleSentenceStatic";
import PutInOrderWord from "../templates/PutInOrderdWord";
import SentencesList from "../templates/SentencesList";
import ListenAndChoose from "../templates/ListenAndChoose";
import TextDialogDnD from "../templates/TextDialogDnD";
import LeadInText from "../templates/LeadInText";
import MatchSentences from "../templates/MatchSentences";
import ChooseCorrectAnswer from "../templates/ChooseCorrectAnswer";
import ImagePhraseTypes from "../templates/ImagePhraseTypes";
import ReactGA from 'react-ga';
import { getUserEmail, getUserId } from "../helpers/user";


class Exercises extends Component {
  constructor(props) {
    super(props)

    this.state = {
      video: 0,
      blockIndex: this.props.match.params.block,
      contentIndex: 0,
      isPlaying: false,
      audioUrl: "",
      preVideoId: "",
      postVideoId: "",
      isLoading: false,
      VideoIsLoading: false,
      lessonId: this.props.match.params.lessonId,
      lessonLoading: true,
      lessonContent: {},
      lessonError: false,
      endLesson: false,
      specificUrl: null,
      forceAudio: false,
      forceComplent: false,
      indexComplent: -1,
      checked: false,
      recordingAttempts: 0,
      bookId: 0,
      usage: null,
      userId: null
    }
  }
  setChecked = (isChecked) => {
    this.setState({
      checked: isChecked
    })
  }

  setforceAudio = () => {
    this.setState({
      forceAudio: true,
      contentIndex: 1,
    })
    return
  }

  getVideo(lessonId) {
    getPrePostVideo(lessonId)
      .then(response => response.data)
      .then((response) => {
        function getPreVideo(value) {
          return value.video_kind === '1'
        }
        function getPostVideo(value) {
          return value.video_kind === '2'
        }
        let lessonContent = this.state.lessonContent

        if (response.length === 0) {
          lessonContent.blocks[0].loading = false
          lessonContent.blocks[this.state.lessonContent.blocks.length - 1].loading = false

          lessonContent.blocks.shift()
          lessonContent.blocks.pop()

          this.setState({
            lessonContent
          })
          return
        }

        var pre_video = response.filter(getPreVideo);
        var pre_video_id = pre_video[0].video_id

        var post_video = response.filter(getPostVideo);
        var post_video_id = null
        if (post_video.length > 0) {
          post_video_id = post_video[0].video_id
        }

        lessonContent.blocks[0].videoId = pre_video_id
        lessonContent.blocks[0].loading = false
        lessonContent.blocks[this.state.lessonContent.blocks.length - 1].videoId = post_video_id
        lessonContent.blocks[this.state.lessonContent.blocks.length - 1].loading = false

        this.setState({
          preVideoId: pre_video_id,
          postVideoId: post_video_id,
          lessonContent
        })
      }).catch((error) => {
        console.log(error)
      })
  };

  zeraContent() {
    this.setState({ contentIndex: 0 })
  }

  keepPlaying() {
    let indexBlock = this.state.blockIndex
    const template = this.state.lessonContent.blocks[indexBlock].template

    // return true

    switch (true) {
      case (template === 'put_in_order_word_sentences'):
      case (template === 'put_in_order_words_sentences'):
      case (template === 'read_and_match'):
        return false

      default:
        return true
    }
  }

  getOS() {
    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = 'unknown';

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  getBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
      return 'Opera'
    }
    else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      return 'Chrome'
    }
    else if (navigator.userAgent.indexOf("Safari") !== -1) {
      return 'Safari'
    }
    else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return 'Firefox'
    }
    else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode)) //IF IE > 10
    {
      return 'IE'
    }
    else {
      return 'unknown'
    }
  }

  getAudioUrl() {
    let indexBlock = this.state.blockIndex
    let type = this.state.lessonContent.blocks[this.state.blockIndex].template
    let content = this.state.lessonContent.blocks[indexBlock].content[this.state.contentIndex]
    let complement = this.state.lessonContent.blocks[indexBlock].complement
    let skipAudios = ['maze', 'mosaic_2_pt', 'mosaic_2_en', 'match', 'match_the_sentences']
    let urlBucket = `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/audios/`
    if (skipAudios.includes(type)) {
      // 'mosaic_2_pt', 'mosaic_2_en', 'mosaic_pt', 'mosaic_en'

      if (content && content.picture) {
        this._step('forward')
        return
      }

      if (content && type === 'match_the_sentences' && content.type === 'drill' && !this.state.forceAudio) {
        this._step('forward')
        return
      }
      if (content && type === 'maze' && content.type === 'drill' && !this.state.forceAudio) {
        this._step('forward')
        return
      }
    }
    if (type === 'match_the_sentences' && this.state.forceComplent) {
      if (this.state.indexComplent < complement.respostas.length){
      if (this.getBrowser() === 'Safari' || this.getOS() === 'iOS') {
        return urlBucket + complement['respostas'][this.state.indexComplent].sound_mp3
      } else {
        return urlBucket + complement['respostas'][this.state.indexComplent].sound
      }
      }else{
        this.setState({ // avança o Bloco
          contentIndex: 0,
          blockIndex: parseFloat(indexBlock) + 1
        })
      }
    }
    if (this.state.lessonContent.blocks[this.state.blockIndex].template === 'read_and_match') {
      return urlBucket + this.state.specificUrl
    }

    let lessonContent = this.state.lessonContent
    if (this.state.lessonContent.blocks[indexBlock].template === "dialog_and_role_play" && this.state.lessonContent.blocks[indexBlock].content.length <= 1) {
      lessonContent.blocks[indexBlock].content = [...lessonContent.blocks[indexBlock].content, ...lessonContent.blocks[indexBlock].content[0].sentences]
      this.setState({ lessonContent })
    }
    if (this.state.lessonContent.blocks[indexBlock].content.length > 0) {
      let notSkipComplement = ['listen_and_drag_to_put_in_order']
      let audioName = ''
      if (this.getBrowser() === 'Safari' || this.getOS() === 'iOS') {
        if (notSkipComplement.includes(type) && content.type === 'drill') {
          audioName = complement.dialog.sound_mp3 || complement.dialog.sound
          return urlBucket + audioName
        }
        else {
          audioName = content.sound_mp3 || content.sound
          return urlBucket + audioName
        }
      } else {
        if (notSkipComplement.includes(type) && content.type === 'drill') {
          audioName = complement.dialog.sound || complement.dialog.sound_mp3
          return urlBucket + audioName
        }
        else {
          audioName = content.sound || content.sound_mp3
          return urlBucket + audioName
        }
      }
    }
  }

  onDataReceivedFromChild = (data) => {
    this.setState({
      recordingAttempts: data
    })
  };


  templateSelector(type) {
    let indexBlock = this.state.blockIndex
    let data = this.state.lessonContent.blocks[indexBlock].content[this.state.contentIndex]

    if (this.state.lessonContent.blocks[indexBlock].content.length !== 0) {
      data.section = this.state.lessonContent.blocks[indexBlock].section;
      data.section_id = this.state.lessonContent.blocks[indexBlock].sectionId;
      data.book_name = this.state.lessonContent.lessonInfos.lBook
    }

    const blockData = this.state.lessonContent.blocks[indexBlock]

    switch (type) {
      case 'double_sentence_en':
        return <DoubleSentence data={data} contentIndex={this.state.contentIndex} /> // 1

      case 'double_sentence_static_en':
        return <DoubleSentenceStatic data={data} en={true} contentIndex={this.state.contentIndex} /> // 1

      case 'double_sentence_static_pt':
        return <DoubleSentenceStatic data={data} en={false} contentIndex={this.state.contentIndex} /> // 1

      case 'double_sentence_pt':
        return <DoubleSentence data={data} contentIndex={this.state.contentIndex} /> // 2

      case 'simple_sentence':
        return <SimpleSentence data={data} contentIndex={this.state.contentIndex} /> // 3 
      
      case 'simple_sentence_vr':
        return (
          <SimpleSentenceVr
            data={data}
            contentIndex={this.state.contentIndex}
            callbackExercisesRecordingAttempts={this.onDataReceivedFromChild}
          />
        ); // 3 

      case 'double_sentence_highlighted_en':
        return <DoubleSentenceHL data={data} contentIndex={this.state.contentIndex} /> // 4

      case 'simple_text_highlighted':
        return <SimpleTextHL blockData={blockData} contentIndex={this.state.contentIndex} /> // 5

      case 'sentences_list':
        return <SentencesList blockData={blockData} contentIndex={this.state.contentIndex} /> // 5

      case 'speak_right_single':
        return <SpeakRightSingle blockData={blockData} contentIndex={this.state.contentIndex} /> // 6

      case 'linking_sounds':
        return <LinkingSounds data={data} contentIndex={this.state.contentIndex} />

      case 'words_in_context_single':
        return <WordsInContext data={data} double={false} contentIndex={this.state.contentIndex} /> // 8

      case 'words_in_context_double':
        return <WordsInContext data={data} double={true} contentIndex={this.state.contentIndex} /> // 9

      case 'web_talk':
        return <WebTalk blockData={blockData} contentIndex={this.state.contentIndex} /> // 10

      case 'conversation_in_trio':
        return <ConversationInTrio blockData={blockData} contentIndex={this.state.contentIndex} /> // 11

      case 'conversation_in_two':
        return <ConversationInTwo blockData={blockData} contentIndex={this.state.contentIndex} /> // 12

      case 'text_dialogues_illustrated':
        return <TextIllustrated data={data} contentIndex={this.state.contentIndex} /> // 13

      case 'fluency_in_pairs':
        return <FluencyInPairs blockData={blockData} contentIndex={this.state.contentIndex} /> // 14

      case 'image_audio':
        return <ImageAudio blockData={blockData} contentIndex={this.state.contentIndex} /> // 15

      case 'image_subtitle':
        return <ImageSubtitle blockData={blockData} contentIndex={this.state.contentIndex} /> // 15

      case 'text_pages_illustrated':
        return <TextPagesIllustrated blockData={blockData} contentIndex={this.state.contentIndex} /> // 16

      case 'mosaic_pt':
        return <Mosaic blockData={blockData} en={false} contentIndex={this.state.contentIndex} getBrowser={this.getBrowser} getOS={this.getOS} /> // 17

      case 'mosaic_en':
        return <Mosaic blockData={blockData} en={true} contentIndex={this.state.contentIndex} getBrowser={this.getBrowser} getOS={this.getOS} /> // 18

      case 'mosaic_2_en':
        return <Mosaic blockData={blockData} en={true} second={true} contentIndex={this.state.contentIndex} getBrowser={this.getBrowser} getOS={this.getOS} /> // 18

      case 'mosaic_2_pt':
        return <Mosaic blockData={blockData} en={false} second={true} contentIndex={this.state.contentIndex} getBrowser={this.getBrowser} getOS={this.getOS} /> // 18

      case 'irregular_verbs':
        return <IrregularVerbs blockData={blockData} contentIndex={this.state.contentIndex} /> // 19

      case 'read_and_match':
        return <DndProvider backend={HTML5Backend}>
          <ReadMatch getBrowser={this.getBrowser} getOS={this.getOS} blockData={blockData} playSpecific={(sound) => {
            this.setState({ specificUrl: sound })
            this.footer._replay()
          }} contentIndex={this.state.contentIndex} />
        </DndProvider>  // 20

      case 'listen_and_match':
        return <DndProvider backend={HTML5Backend}>
          <ListenMatch blockData={blockData} step={(type) => this._step(type)} contentIndex={this.state.contentIndex} />
        </DndProvider>  // 21

      case 'match':
        return <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          <Match
            blockData={blockData}
            contentIndex={this.state.contentIndex}
            togglePlay={() => this._play(false)}
            getBrowser={this.getBrowser}
            getOS={this.getOS} />
        </DndProvider>  // 22

      case 'image_carousel':
        return <Carousel blockData={blockData} contentIndex={this.state.contentIndex} /> // 23

      case 'single_card':
        return <SingleCard data={data} contentIndex={this.state.contentIndex} /> // 24

      case 'irregular_verbs_test_yourself':
        return <IrregularVerbsTestYourself data={data} contentIndex={this.state.contentIndex} /> // 25

      case 'speak_right_double':
        return < SpeakRightDouble blockData={blockData} contentIndex={this.state.contentIndex} /> // 26

      case 'irregular_verbs_3':
        return <IrregularVerbs3 blockData={blockData} contentIndex={this.state.contentIndex} /> // 27

      case 'text_pages_highlighted':
        return <TextPagesHL data={data} contentIndex={this.state.contentIndex} /> // 4

      case 'audio':
        return <Audio data={data} contentIndex={this.state.contentIndex} resetPlayer={() => this.footer._resetPlayer()} blockData={blockData} />
      
      case 'prePost_video':
        return <PrePost paused={!this.state.isPlaying} blockData={blockData} contentIndex={this.state.contentIndex} />

      case 'text_pages_scrambled':
        return <DndProvider backend={HTML5Backend}>
          <TextPagesScrambled blockData={blockData} contentIndex={this.state.contentIndex} step={(type, force) => this._step(type, force)} />
        </DndProvider>

      case 'workbook':
        return <Workbook blockData={blockData} contentIndex={this.state.contentIndex} />

      case 'dialog_and_role_play':
        return <DndProvider backend={HTML5Backend}><DialogRolePlay blockData={blockData} contentIndex={this.state.contentIndex} /> </DndProvider>

      case 'listen_and_drag_to_put_in_order':
        return <ListenDrag blockData={blockData} contentIndex={this.state.contentIndex} step={(type) => this._step(type)} isPlaying={this.state.isPlaying} togglePlay={(play) => this._play(!play)} />

      case 'put_in_order_word_sentences':
        return <PutInOrderWord blockData={blockData} step={(type) => this._step(type)} contentIndex={this.state.contentIndex} />

      case 'put_in_order_words_sentences':
        return <PutInOrderWord blockData={blockData} step={(type) => this._step(type)} contentIndex={this.state.contentIndex} />

      case 'listen_and_choose':
        return <ListenAndChoose blockData={blockData} step={(type, force) => this._step(type, force)} contentIndex={this.state.contentIndex} />

      case 'maze':
        return <DndProvider backend={HTML5Backend}><Maze blockData={blockData} step={(type, force) => this._step(type, force)} contentIndex={this.state.contentIndex} setforceAudio={this.setforceAudio} /> </DndProvider>

      case 'song':
        return <DndProvider backend={HTML5Backend}><Song blockData={blockData} contentIndex={this.state.contentIndex} datas={this.myConcat(blockData.complement.song.lyrics, blockData.complement.song.wordsToChange)} /></DndProvider>

      case 'text_dialogue_drag_drop':
        return <DndProvider backend={HTML5Backend}><TextDialogDnD step={(type) => this._step(type)} blockData={blockData} shuffledData={this.shuffle(this.getHilighted(this.textDialogConcat(blockData.complement.frases, blockData.complement.palavras)))} datas={this.textDialogConcat(blockData.complement.frases, blockData.complement.palavras)} /></DndProvider>

      case 'lead_in_text':
        return <LeadInText blockData={blockData} step={(type) => this._step(type)} contentIndex={this.state.contentIndex} />

      case 'match_the_sentences':
        return <DndProvider backend={HTML5Backend}>
          <MatchSentences
            blockData={blockData}
            step={(type) => this._step(type)}
            contentIndex={this.state.contentIndex}
            setforceAudio={this.setforceAudio}
            setChecked={this.setChecked}
            checked={this.state.checked} />
        </DndProvider>

      case 'choose_correct_answer':
        return <ChooseCorrectAnswer blockData={blockData} contentIndex={this.state.contentIndex} blockIndex={this.state.blockIndex} />

      case 'image_phrase_type':
        return <DndProvider backend={HTML5Backend}>
          <ImagePhraseTypes
            blockData={blockData}
            step={(type) => this._step(type)}
            shuffledData={this.shuffle(
              this.getHilighted(
                this.textDialogConcat(
                  blockData.complement.frases,
                  blockData.complement.palavras
                )
              )
            )}
            datas={this.textDialogConcat(blockData.complement.frases, blockData.complement.palavras)} />
        </DndProvider>


      default:
        return "No template available"
    }

  }

  myConcat(a, b) {
    let array = a.map(function (item) {
      return item + " - "
    })
    for (let i = 0; i < b.length; i++) {
      for (let j = 0; j < array.length; j++) {
        if (array[j].includes(b[i])) {
          array[j] = array[j].replace(b[i], "[" + b[i] + "]")
          b = b.filter(m => {
            return m !== b[i];
          })
          i--
        }
      }
    }
    return array.join('');
  }

  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  getHilighted(text) {
    let splitText = text.match(/\[(.*?)\]/g);

    let textObj = [];
    for (let i = 0; i < splitText.length; i++) {
      if (splitText[i].includes("[") || splitText[i].includes("]")) {
        textObj.push({
          word: splitText[i].replace("[", "").replace("]", ""),
          lastDroppedItem: null
        });
      }
    }

    return textObj;
  }

  textDialogConcat(frase, palavras) {
    let PhrasesToSelectCounter = 0
    let finalArray = frase
    if(typeof frase !== 'string'){
      finalArray = frase.map(function (item) {
        return item + " - "
      })
    }

    let bracketAmount = 0
    for (let x = 0; x < frase.length; x++) {
      bracketAmount = frase[x].length - frase[x].replace(/\[/g, "").length

      if (frase[x].includes('[')){
        PhrasesToSelectCounter = PhrasesToSelectCounter + bracketAmount
      } 
    }
    
    if(typeof frase !== 'string'){
      finalArray = finalArray.join('')
    }

    for (let j = 0; j < PhrasesToSelectCounter; j++) {
      let value = j + 1
      let rule = '\\'+ '\[' + value + '\\' + '\]'
      let regex = new RegExp(rule, "g")

      finalArray = finalArray.replace(regex, "[" + palavras[j] + "]")
    }
    return finalArray;
  }

  async trackUsage() {
    const usage = this.state.usage;
    const endTime = new Date();
    const totalTime = endTime - new Date(localStorage.getItem("progress_tracker_start_time"));
    const totalTimeInSeconds = Math.round(totalTime / 1000);
    const userId = await getUserId()
    const email = await getUserEmail()
    const usageData = {
      userId: userId,
      lessonId: Number.parseInt(this.state.lessonId),
      email: email,
      lessonName: this.state.lessonContent.lessonInfos.lName,
      bookName: this.state.lessonContent.lessonInfos.lBook,
      progress: [Number.parseInt(this.state.blockIndex)],
      totalSections: this.state.lessonContent.blocks.length,
      totalTime: totalTimeInSeconds,
    }
    if (!usage) { // If there is no usage, we need to do a POST
      return createUsage(usageData);
    } else if (!usage.progress.includes(Number.parseInt(this.state.blockIndex))) { // We only send a PATCH if the user has not done this block yet
      return updateUsage(usageData);
    }
    return Promise.reject("User has already done this block.");
  }

  nextBlock() {
    const indexBlock = this.state.blockIndex;

    this.trackUsage()
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({ // avança o Bloco
            contentIndex: 0,
            blockIndex: parseFloat(indexBlock) + 1
          })
        });
  }

  _step(type, force = false) {
    let indexBlock = this.state.blockIndex
    let template = this.state.lessonContent.blocks[indexBlock].template
    let forceComplent = this.state.forceComplent
    let contentIndex = this.state.contentIndex
    let complement = this.state.lessonContent.blocks[indexBlock].complement
    let content = this.state.lessonContent.blocks[indexBlock].content

    const hasNext = contentIndex < content.length - 1
    const hasNextRespostas = this.state.indexComplent > -1 && this.state.hasNextComplent && template === 'match_the_sentences' 

    let type_exercise;

    if (this.state.lessonContent.blocks[indexBlock].content.length !== 0) {
      type_exercise = this.state.lessonContent.blocks[indexBlock].content[contentIndex].type
    }

    let match_the_sentences_complement = !forceComplent && template === 'match_the_sentences' && complement && this.state.indexComplent < complement.respostas.length && hasNext


    if (type === 'skipForward') {
      this.props.match.params.block = parseFloat(indexBlock) + 1
      this.nextBlock();
      return
    }
    else if (type === 'forward') { // ir pra frente
      // step block forward if template is read and match
      
      if (template === 'simple_sentence_vr' && type_exercise === 'drill') {
        if (this.state.lessonContent.blocks[indexBlock].content.length !== 0) {

          if (force === false || this.state.recordingAttempts === 0) {
            return
          }
          
        }
      }

      if (template === 'read_and_match') {

        if (content[contentIndex].type === "instruction") {
          if (this.state.lessonContent.blocks[indexBlock].content.length !== 0) {
            this.setState({
              contentIndex: content.length - 1,
            })
            return;
          }
        }
        this.nextBlock();
        this.props.match.params.block = parseFloat(indexBlock) + 1
        return
      }
      // tratativas pra reproduzir os complementos do template match_the_sentences
      else if (hasNext || match_the_sentences_complement || hasNextRespostas) {// avança o content
        if (!this.state.hasNextComplent && complement && complement.respostas && complement.respostas.length > 0){
          this.setState({
            hasNextComplent:true,
          })
        }
        let skipForwardInstruction = ['maze', 'match_the_sentences', 'listen_and_drag_to_put_in_order']
        if (skipForwardInstruction.includes(template) && content[contentIndex].type === 'instruction') {
          if (template === 'listen_and_drag_to_put_in_order') {
            this.setState({
              contentIndex: content.length - 1,
              isPlaying: false
            })
          } else {
            this.setState({
              contentIndex: content.length - 1,
            })
          }
          return
        }
        if (force || template !== 'match_the_sentences') {
          // Verificando se ele ta pulando instrução do match_the_sentences ou drill
          if ((template !== 'match_the_sentences') && (template !== 'read_and_match')) {
            this.setState({
              contentIndex: contentIndex + 1
            })
          } else if (template === 'match_the_sentences') {
            // Verificando se o indexComplent ja foi iniciado
            if (this.state.indexComplent === -1) {
              // Index não foi iniciado, incrementa mais 2 e volta pra coluna da esquerda
              this.setState({
                contentIndex: contentIndex + 1,
                indexComplent: this.state.indexComplent + 2,
                forceComplent: false
              })
            }
            else {
              if (!this.state.checked || !content[contentIndex + 1]) {
                this.nextBlock();
              } else {
              
              // Index ja iniciado, incrementa mais 1 e volta pra coluna da esquerda
              this.setState({
                contentIndex: contentIndex + 1,
                indexComplent: this.state.indexComplent + 1,
                forceComplent: false
              })}
            }
          }
        } else if (complement.respostas.length > 0 && this.state.forceAudio && this.state.indexComplent === -1 && template === 'match_the_sentences') {
          this.setState({
            indexComplent: this.state.indexComplent + 1,
            forceComplent: complement.respostas.length > 0
          })
        }
        else if (complement.respostas.length > 0 && this.state.forceAudio && this.state.indexComplent > 0 && template === 'match_the_sentences' && !forceComplent) {
          this.setState({
            forceComplent: !forceComplent
          })
          if (this.state.indexComplent === complement.respostas.length - 1){
            this.setState({
              hasNextComplent:false,
            })
          }
        } else if (forceComplent && this.state.forceAudio && this.state.hasNextComplent) {
          if (!this.state.checked) {
            this.nextBlock();
          } else {
          this.setState({
            indexComplent: this.state.indexComplent + 1,
            forceComplent: !forceComplent,
            contentIndex: contentIndex + 1
          })}       
        }
      } else {
        if (indexBlock < this.state.lessonContent.blocks.length - 1) {
          let notSkipForward = ['listen_and_drag_to_put_in_order', 'choose_correct_answer', 'lead_in_text', 'maze', 'match_the_sentences', 'text_pages_scrambled', 'mosaic_en', 'mosaic_pt', 'mosaic_2_en', 'mosaic_2_pt', 'match', 'listen_and_choose']
          if (!notSkipForward.includes(template) || force) {
            this.setState({
              indexComplent: 0,
              forceAudio: false,
              forceComplent: false
            })
            this.nextBlock();
            this.props.match.params.block = parseFloat(indexBlock) + 1
          }
          return
        } else if (indexBlock === this.state.lessonContent.blocks.length - 1) {
          this.setState({
            endLesson: true,
            indexComplent: 0,
            forceAudio: false,
            forceComplent: false
          })
          return
        }
      }
    } else { // para voltar

      if(template === 'read_and_match' && content[contentIndex].type === "drill" && contentIndex > 0){
        this.setState({
          contentIndex: 0,
        })
        return;
      }

      if (this.state.contentIndex > 0) {
        this.setState({
          contentIndex: this.state.contentIndex - 1,
          indexComplent: this.state.indexComplent - 1,
        })
        return
      } else {
        if (indexBlock > 0) {
          this.setState({
            contentIndex: 0,
            blockIndex: parseFloat(indexBlock) - 1,
            indexComplent: 0,
            forceAudio: false,
            forceComplent: false
          })
          this.props.match.params.block = parseFloat(indexBlock) - 1
        }
        return
      }
    }
  }

  _play(varPlay) {
    this.setState({ isPlaying: varPlay })
  }

  setStartTime() {
    const startTime = new Date().toISOString();
    localStorage.setItem("progress_tracker_start_time", startTime);
  }

  async setupUsageProgressTracker() {
    if (!this.state.userId) {
      const userId = await getUserId();
      this.setState({ userId: userId });
    }

    try {
      const usageProgressTrackerData = await getUsage(this.state.userId, this.props.match.params.lessonId)
      this.setState({ usage: usageProgressTrackerData.data });
    } catch (err) {
      // This means there is no registry of this user in this lesson on the Progress Tracker
      this.setState({ usage: null });
    } finally {
      this.setStartTime();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ blockIndex: nextProps.match.params.block, contentIndex: 0 })
  }

  componentDidMount() {
    getLessonContent(this.props.match.params.lessonId).then(res => {

      this.setupUsageProgressTracker();
      this.getVideo(this.props.match.params.lessonId)
      res.blocks.unshift({
        block_id: 0,
        complement: {},
        content: [{ sound: null, en: "", pt: "", id: 0, instruction: "Pre Video", duration: 1, type: "video" }],
        num: 0,
        section: "Pre Video",
        subSection: "",
        subSectionId: 0,
        template: "prePost_video",
        videoId: "",
        loading: true
      })
      res.blocks.push({
        block_id: 0,
        complement: {},
        content: [{ sound: null, en: "", pt: "", id: 0, instruction: "Post Video", duration: 1, type: "video" }],
        num: res.blocks.length,
        section: "Post Video",
        subSection: "",
        subSectionId: 0,
        template: "prePost_video",
        videoId: "",
        loading: true
      })
      this.setState({
        lessonLoading: false,
        lessonContent: res,
        lessonError: false,
        bookId: res.lessonInfos.lBookId
      })
    }).catch(err => {
      this.setState({
        lessonLoading: false,
        lessonError: true
      })
      console.log('getLessonContent ERROR: ', err)
    })

    const trackingId = 'UA-1839981-59'
    ReactGA.initialize(trackingId)

    ReactGA.event({
      category: "Catchup",
      action: "Started Lesson",
      label: this.props.match.params.lessonId.toString()
    })

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.blockIndex !== this.state.blockIndex) {
      this.setupUsageProgressTracker();
    }
  }

  isPrePost() {
    if (this.state.blockIndex >= 0 && this.state.blockIndex < this.state.lessonContent.blocks.length) {
      return false
    } else {
      return true
    }
  }

  /* LOGICA TEMPORARIA PARA TESTAR COR DIFERENTE PARA LIVRO TEENS 4 3RD EDITION */
  defineBackgroundRectColor() {
    const TEENS_4_3RD_EDITION = 51;
    let bgColor;
    if (this.state.bookId === TEENS_4_3RD_EDITION) {
      bgColor = '#33aae3bd'
    }
    if (bgColor) return { backgroundColor: bgColor }
  }

  render() {
    const { lessonId } = this.props.match.params
    
    return (
      <div>
        <Header exercise={true} />
        <div className="main">
            {
              !this.state.lessonLoading && !this.state.lessonError &&
              <div style={{ position: 'absolute', left: 0, top: 5, opacity: 0.6, fontSize: 8, width: '250px', zIndex: 9999 }}>
                <div>
                  Template: {this.state.lessonContent.blocks[this.state.blockIndex].template}
                </div>
              </div>
            }
          
          <div className="content">
            {
              !this.state.lessonLoading && !this.state.lessonError && !this.state.endLesson &&
              <WizTabLeftNav data={[this.state.lessonContent.blocks, this.props.match.params]} />
            }
            <div className="background-image content-class">          
              <div className="background-rect" style={this.defineBackgroundRectColor()}>             
                {
                  this.state.lessonLoading ?
                    <div className="warning-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        Loading lesson...
                      </div>
                    </div>
                    :
                    this.state.lessonError ? // if failed to load lesson
                      <div className="warning-center" style={{ left: '40%' }}>
                        <div style={{ marginTop: 10 }}>
                          Error while loading lesson, please try again later.
                        </div>
                      </div>
                      :
                      this.state.endLesson
                        ? <EndLesson lessonId={lessonId} catchupId={this.props.match.params.catchupId} />
                        : this.templateSelector(this.state.lessonContent.blocks[this.state.blockIndex].template)
                }
              </div>
            </div>
          </div>
        </div>
        {
          !this.state.lessonLoading && !this.state.lessonError &&
          <Footer
            step={(type, force = false) => this._step(type, force)}
            audioUrl={this.isPrePost() ? "" : this.getAudioUrl()}
            keepPlaying={this.isPrePost() ? "" : this.keepPlaying()}
            contentIndex={this.state.contentIndex}
            ref={(ref) => this.footer = ref}
            togglePlay={(playVideo) => this._play(!playVideo)}
            disablePlay={this.state.lessonContent.blocks[this.state.blockIndex].template === 'text_dialogue_drag_drop'}
            playAudio={this.state.isPlaying}
          />
        }
      </div>
    )
  }
}

export default Exercises;
