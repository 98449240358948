import React, { Component } from "react";

class ImageSubtitle extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  getImageUrl(image){
      return `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/` + image 
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card">
            {
                blockData.content[contentIndex].type === 'instruction' ?
                <div>
                    <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                    <h5 className="instruction-text">{ blockData.content[contentIndex].instruction }</h5>
                </div>
                :
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100%', width: '100%'}}>
                    <div style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', fontSize: 24, flex: 0.2}}> { blockData.complement.titulo } </div>
                    <img alt="Subtitle" style={{ padding: 10, alignSelf: 'center', objectFit: 'contain', flex: 0.8 }} src={this.getImageUrl(blockData.complement.image.img)} />
                    <div style={{ display: 'flex', alignItems: 'center', padding: 10, flex: 0.2 }}>
                       { blockData.content[contentIndex].en }
                    </div>
                </div>
                
            }
      </div>
    )
  }
}

export default ImageSubtitle
