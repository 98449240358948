import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../assets/leftNav.css";

class WizTabLeftNav extends Component {

  render() {
    const { catchupId, lessonId, block } = this.props.data[1];
    return (
      <div className="left-nav">
        <div className="wiz-tab-leftnav">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <ul className="list-group" >
                    {this.props.data[0].map(function (res, index) {
                      let blockId = index
                      return (
                        <li key={index} className="li-style">
                          <Link
                            to={`/catchupId/${catchupId}/lesson/${lessonId}/${blockId}`}
                            className={
                              "list-group-item " +
                              (blockId === block ? "active" : "")
                            }
                          >
                            <span className="navCounter">{index}</span>
                          </Link>
                          <span className="navText"> {res.section}</span>
                          <span className="navText"> {res.subSection}</span>
                          <div className="vl" />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WizTabLeftNav;
