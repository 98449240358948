import React, { Component } from "react";
import Collapsible from "react-collapsible";
import '../assets/templates/DoubleSentenceHL.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons'

class DoubleSentenceHL extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      openSecond: false,
      index: 0
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.contentIndex !== this.props.contentIndex){
      this.setState({
        open: false,
      })
    }
  }

  _reset(){
    this.setState({
      open: false
    })
  }

  getHilighted(text){          
    let splitText = text.split('[');
    let textObj = []
    for(let i = 0; i < splitText.length; i++){
      if(splitText[i].includes(']')){
        let boldText = splitText[i].split(']')
        textObj.push({word: boldText[0].replace("]",""), highLight: true})
        textObj.push({word: boldText[1], highLight: false})
      }else{
        textObj.push({word: splitText[i], highLight: false})
      }
    }
    return textObj
  }

  render() {
    const { data } = this.props;
    return (
      <div className="default-card">
      <div>
      </div>
            {
              data.type === 'instruction'
                ?
                <div>
                  <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                  <h5 className="instruction-text">{ data.instruction }</h5>
                </div>
                :
                <div>
                    <h2 className="h2-highlight">
                        <Collapsible 
                        triggerTagName="div" 
                        className="collapse-opened" 
                        trigger={
                            <div>
                                {
                                    this.getHilighted(data.en).map((item)=>{
                                        return(
                                            <span style={{color: item.highLight ? 'rgb(47, 157, 201)' : 'white'}}>
                                                { item.word }
                                            </span>
                                        )
                                    })
                                }
                            </div>
                        }
                        open={this.state.open}
                        handleTriggerClick={()=>{}}
                        onClosing={()=>{this.setState({ isClosing: true })}}
                        onOpening={()=>{this.setState({ isClosing: false })}}
                        >
                        { !this.state.isClosing ? data.pt : null }
                        </Collapsible>
                    </h2>
                    <div className="highlight-icon-container">
                        <div className="highlight-icon" onClick={()=>{this.setState({ open: !this.state.open })}}>
                            <FontAwesomeIcon className="icon-step" icon={ faUndoAlt } />
                        </div>
                    </div>
                </div>
            }
      </div>
    )
  }
}

export default DoubleSentenceHL;
