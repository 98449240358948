import React, { Component } from "react";
import '../assets/templates/WebTalk.css';

class WebTalk extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      index: 0
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.contentIndex !== this.props.contentIndex){
      this.setState({
        open: false,
      })
    }
  }

  getImage(type){
      switch(type){
        case "femalemale":
            return 1

        case "malefemale":
            return 2

        case "femaleCouple":
            return 3

        default:
            return 1
      }
  }

  getBubblePosition(type){
    switch(type){
        case "femalemale":
            return {top: '60%', left: '57%'}

        case "malefemale":
            return {top: '43%', left: '70%'}

        case "femaleCouple":
            return {top: '40%', left: '65%'}

        default:
            return {top: '60%', left: '57%'}
      }
  }

  getSecondBubblePosition(type){
    switch(type){
        case "femalemale":
            return {top: '50%', left: '7%'}

        case "malefemale":
         return {top: '53%', left: '5%'}

        case "femaleCouple":
            return {top: '55%', left: '7%'}

        default:
            return {top: '50%', left: '7%'}
      }
  }

  render() {
    const { blockData, contentIndex } = this.props;
    
    return (
      <div className="conversation-card">
        <div className="webtalk-container">
            <div className={"webtalk-bg " + "web_talk" + this.getImage(blockData.complement.imgType)}>
                <div 
                className="webtalk-bubble"
                style={{ top: this.getBubblePosition(blockData.complement.imgType).top,
                        left: this.getBubblePosition(blockData.complement.imgType).left}}>
                    { blockData.content[contentIndex].en.split("/")[1] }
                </div>
                <div 
                className="webtalk-bubble2"
                style={{ top: this.getSecondBubblePosition(blockData.complement.imgType).top,
                        left: this.getSecondBubblePosition(blockData.complement.imgType).left}}>
                    { blockData.content[contentIndex].en.split("/")[0] }
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default WebTalk;
