import React from 'react'
import { DragSource } from 'react-dnd'
const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '7rem',
    width: '6rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    color: 'black',
    padding: '1rem',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    float: 'left',
    cursor: 'move'
    
}
export const Box = ({ name, isDropped, isDragging, connectDragSource }) => {
  const opacity = isDragging ? 0.4 : 1
  const backColor = isDropped ? 'transparent':  'white' 
  return connectDragSource(
    <div style={{ ...style, opacity, backgroundColor: backColor}}>{isDropped ? '': name}</div>,
  )
}
export default DragSource(
  props => props.type,
  {
    beginDrag: props => ({ name: props.name }),
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(Box)
