import React, { Component } from "react";
import "../assets/templates/Mosaic.css";
import ReactPlayer from 'react-player'
import { getLanguagePack } from '../service/service.js'

class Mosaic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myContent: [],
      flipped: false,
      cardData: [],
      languagePackage: getLanguagePack(),
      songPlaying: '',
      playing: false
    };
  }

  componentDidMount() {
    this.setState({ cardData: this.getData(this.getCurrentContent(this.props.blockData.content)) });
  }
  componentDidUpdate(prevProps){


    if(prevProps.contentIndex !== this.props.contentIndex){
  
      this.setState({ cardData: this.getData(this.getCurrentContent(this.props.blockData.content)) });
    }
  }
  onPlayingFalse = () => {
    this.setState({ playing: false });
  }

  splitContent(data) {
    let lastType = ""; 
    /**
     * using reduce to split instruction and content block
     * example:
     * [instruction, content, instruction, content]
     * [instruction, instruction, content]
     */
    const transformedData = data.reduce(
      (accumulator, currentValue, currentIndex) => {
        const content = {...currentValue, contentIndex: currentIndex};
        const hasAccumulator = accumulator.length > 0;
        if(currentValue.type === "instruction"){
          lastType = "instruction";
          return [...accumulator, [content]];
        }
        if(hasAccumulator && (lastType === "content")){
          const lastIndex = accumulator.length - 1;
          accumulator[lastIndex].push(content);   
        }else{
            accumulator.push([content]);
        }
        lastType = "content";
        return accumulator;
      },
      []
    );
    return transformedData;
  }

  getCurrentContent(data){
    const splittedData = this.splitContent(data);
    const filteredData = splittedData.map((value)=>{
    const result = value.filter((item)=>item.contentIndex === this.props.contentIndex);
      if (result.length>0){
        return value;
      }
      return null;
    })
    const currentContent = filteredData.filter(item=>item !== null)
    return currentContent[0]
  }

  getData(data) {
    return data.filter(e => e.type !== 'instruction')
  }

  flipped(index) {
    let cardData = this.state.cardData;
    if (!cardData[index].flipped) {
      cardData[index].flipped = true;
      let songPlaying = ''
      if (this.props.getBrowser() === 'Safari' || this.props.getOS() === 'iOS') {
        songPlaying = `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/audios/` + cardData[index].sound_mp3
      } else {
        songPlaying = `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/audios/` + cardData[index].sound
      }
      this.setState({ cardData, songPlaying, playing: true });
    }else{
      let cardData = this.state.cardData;
      cardData[index].flipped = false;
      this.setState({ cardData });
    }
  }

  _getMessage(message) {
    return this.state.languagePackage[message] ? this.state.languagePackage[message] : message
  }

  render() {
    const { blockData, contentIndex, second, en } = this.props;

    return (
      <div className="default-card container">
        <ReactPlayer
          url={this.state.songPlaying}
          width={0}
          height={0}
          onEnded={this.onPlayingFalse}
          playing={this.state.playing}
        />
        {blockData.content[contentIndex].type === "instruction" ? (
          <div>
            <h3
              onChange={() => console.log("h3 change")}
              className="instruction-title"
            >
              Instruction
            </h3>
            <h5 className="instruction-text">
              {blockData.content[contentIndex].instruction}
              {this.state.cardData.map((item, index) => {
                return (item.flipped = false);
              })}
            </h5>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%"
            }}
          >
            <h4 style={{ marginLeft: "47px" }}>
              {second
                ? en
                  ? this._getMessage('Tap on the card, listen, and speak.')
                  : this._getMessage('Tap on the card, listen, and speak in English.')
                : this._getMessage('Tap the image to see the word')}
            </h4>
            <br />

            <div className="row">
              {this.state.cardData.map((item, index) => {
                return (
                  <div key={index} className={`Card col-md-4`}>
                    <div
                      style={{
                        display: !this.state.cardData[index].flipped
                          ? "block"
                          : "none"
                      }}
                      className={"Card-Front"}
                      ref={"card"}
                      onClick={() => {
                        this.flipped(index);
                      }}
                      id={"front"}
                    >
                      <div className="Card-itens-front">
                        <img
                          alt="Mosaic"
                          className="mosaic-img"
                          src={`${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/${item.picture
                            }`}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: this.state.cardData[index].flipped
                          ? "block"
                          : "none"
                      }}
                      className={
                        "Card-Back " +
                        (this.state.cardData[index].flipped
                          ? "Card-Back-Flip"
                          : "Card-Front-Flip")
                      }
                      ref={"card"}
                      onClick={() => {
                        this.flipped(index);
                      }}
                      id={"back"}
                    >
                      <div className="Card-itens-back">
                        <h4 style={{ whiteSpace: "pre-line" }}>
                          {en ? item.en.replace(/<br>/g, '\n') : item.pt.replace(/<br>/g, '\n')}

                        </h4>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Mosaic;
