import React, { Component } from "react";
import '../assets/templates/ListenDrag.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move';

const SortableItem = SortableElement(({ value }) => <div className="illustrated-word" style={{
  backgroundColor: typeof value.right == "undefined"
    ? 'white'
    : value.right
      ? '#69f0ae'
      : '#ff5252'
}} >{value.en}</div>);

const SortableList = SortableContainer(({ items }) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableItem key={`item-${value.id}`} index={index} value={value} />
      ))}
    </ul>
  );
});

class ListenDrag extends Component {
  constructor(props) {
    super(props)

    this.state = {
      items: [],
      checked: false,
      buttonMsg: 'Check',
      solved: false
    }
  }

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }

    return array
  }

  _getItems(){
    const { blockData } = this.props
    let items = []

    items = blockData.content.filter(a => a.type !== 'instruction').map( (item, index) => { // add a sort count for drill types
            item.order = index
            return item
    })

    this.setState({
      items: this.shuffleArray(items),
      initialItems: items
    })

  }

  _checkOrder() {
    if (this.state.solved) {
      this.props.step('skipForward')
    }

    if (this.state.checked) {
      this._solve()
      return
    }

    let items = this.state.items
    for (let i = 0; i < this.state.items.length; i++) {
      if (this.state.items[i].order !== i) {
        items[i].right = false
      } else {
        items[i].right = true
      }
    }
    this.setState({
      items,
      checked: true,
      buttonMsg: 'Solve'
    })
  }

  _solve() {
    let items = this.state.items

    items = items.sort((a, b) => { return a.order - b.order }) // sort by order number

    items = items.map(item => { // set all answers as right
      item.right = true
      return item
    })

    this.setState({
      items,
      solved: true,
      buttonMsg: 'Done'
    })
  }

  componentDidMount() {
    this._getItems()
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }))
  }


  getImageUrl(image) {
    return `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/` + image 
  }

  _togglePlay() {
    this.props.togglePlay(this.props.isPlaying)
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card">
        {
          blockData.content[contentIndex].type === 'instruction' ?
            <div>
              <h3 className="instruction-title">Instruction</h3>
              <h5 className="instruction-text">{blockData.content[contentIndex].instruction}</h5>
            </div>
            :
            <div className="illustrated-container" style={{}}>
              <div className="listen-drag-check" onClick={() => { this._checkOrder() }}>
                {
                  this.state.buttonMsg
                }
              </div>
              <div className="listen-drag-left-container">
                <img className="illustrated-img" src={this.getImageUrl(blockData.complement.image.img)} />
                <div className="listen-drag-left-icon">
                  <div className="listen-drag-left-icon-container" style={{cursor: 'pointer'}} onClick={() => this._togglePlay()}>
                    <FontAwesomeIcon style={{ fontSize: 40 }} icon={faVolumeUp} />
                  </div>
                  <div>
                    Listen and put in order
                  </div>
                </div>
              </div>
              <div className="illustrated-words-container">
                <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />
              </div>
            </div>
        }
      </div>
    )
  }
}

export default ListenDrag;
