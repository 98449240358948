import React, { Component } from "react";
import '../assets/templates/ConversationInTrio.css';

class ConversationInTrio extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  // get the balloon position based on who's talking
  getWordPosition(){
    // the position should bounce between first and third, e.g.: 1, 2, 3, 2, 1, 2, 3...

    // initial position
    let f = 1

    // increase flag, defines if it should increase or decrease
    let increase = true
    for(let i = 1; i < this.props.blockData.content.length; i++){
      this.props.blockData.content[i].position = f
      if(f >= 1 && increase && f < 3){
          f = f + 1
          increase = true
      }else if(f <= 3){
          f = f - 1
          if (f === 1){
              increase = true
          }else{
              increase = false
          }
      }

    }
  }

  // get the middle guy direction (right or left)
  getDirection(){
    const { blockData, contentIndex } = this.props;
    if(blockData.content[contentIndex-1].position < blockData.content[contentIndex].position){
        // left
        return 1
    }else{
        // right
        return -1
    }
  }

  setPosition(position){
      switch(position){
        case 1:
            return {top: '40%', left: '0%'}

        case 2:
            return {top: '40%', left: '37%'}

        case 3:
            return {top: '40%', left: '72%'}

        default:
            return {top: '40%', left: '0%'}

      }
  }

  componentDidMount(){
      this.getWordPosition()
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="conversation-card">
      {
                blockData.content[contentIndex].type === 'instruction' ?
                <div>
                    <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                    <h5 className="instruction-text">{ blockData.content[contentIndex].instruction }</h5>
                </div>
                :
                <div className="conversation-container">
                    <div className="conversation-trio-bg-container">
 
                <div className="speech-bubble" style={{
                    top: this.setPosition(blockData.content[contentIndex].position).top,
                    left: this.setPosition(blockData.content[contentIndex].position).left
                    
                    }}>
                    { blockData.content[contentIndex].en }
                </div>
                        <div className="boneco" 
                        style={{
                            transform: 'scaleX('+ this.getDirection() +')',
                        }}
                        />
                    </div>
                </div>
      }
      </div>
    )
  }
}

export default ConversationInTrio;
