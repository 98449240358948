import React, { useState, useCallback, useEffect } from "react";
import Dustbin from "../components/DustBinText";
import Box from "../components/BoxText";
import update from "immutability-helper";

const ImagePhraseTypes = props => {
  const { blockData, datas, shuffledData } = props;
  let [solved, setSolved] = useState(false)
  let title = blockData.complement.titulo ? blockData.complement.titulo : "";
  let start = 0;
  const [dustbins, setDustbins] = useState(getHilighted(props.datas));
  const [droppedBoxNames, setDroppedBoxNames] = useState([]);

  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }

  useEffect(function () {
    if(droppedBoxNames.length === 0 && dustbins.length === 0) {
      setDroppedBoxNames([])
      setDustbins(getHilighted(props.datas))
      setSolved(false)
    }
  }, [dustbins]);

  useEffect(function () {
    setDustbins(getHilighted(props.datas))
  }, [blockData]);

  const handleDrop = useCallback(
    (index, item) => {
      const { name } = item;
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] })
      );
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item
            }
          }
        })
      );
    },
    [droppedBoxNames, dustbins]
  );
  let array = [];
  let countData = getHilighted(datas).length;

  function solve() {
    let allData = []
    let allDustBins = dustbins

    for (let i = 0; i < dustbins.length; i++) {
      allData.push(dustbins[i].word)
      allDustBins[i].lastDroppedItem = { name: dustbins[i].word }
    }

    setDroppedBoxNames(
      update(droppedBoxNames, { $push: allData })
    )
    setDustbins(
      update(dustbins, { $set: allDustBins })
    );

  }

  function tryAgain() {
    let allDustBins = dustbins

    for (let i = 0; i < dustbins.length; i++) {
      allDustBins[i].lastDroppedItem = undefined
    }
    setDroppedBoxNames(
      update(droppedBoxNames, { $set: [] })
    )
    setTimeout(() => {
      setDroppedBoxNames(
        update(droppedBoxNames, { $set: [] })
      )
    }, 100);

    setDustbins(
      update(dustbins, { $set: allDustBins })
    );

    setSolved(false)

  }

  function splitText(getdata, index) {
    let removeParentheses = getdata.match(/\[(.*?)\]/g);

    array.push(removeParentheses[index]);

    start =
      index === 0
        ? start
        : parseInt(
          getdata.indexOf(array[index - 1]) + array[index - 1].length + 1
        );

    let lastString = getdata.indexOf(array[index]);
    let result = getdata.slice(start, lastString);

    return result;
  }

  function lastText(getdata) {
    let lastText = "";

    if (array[countData - 1] !== undefined) {
      lastText = getdata.slice(
        getdata.lastIndexOf(array[countData - 1]) + array[countData - 1].length,
        getdata.length
      );
    }
    return lastText;
  }

  function getHilighted(text) {
    let splitText = text.match(/\[(.*?)\]/g);

    let textObj = [];
    for (let i = 0; i < splitText.length; i++) {
      if (splitText[i].includes("[") || splitText[i].includes("]")) {
        textObj.push({
          word: splitText[i].replace("[", "").replace("]", ""),
          lastDroppedItem: null
        });
      }
    }

    return textObj;
  }

  return (
    <div className="default-card container">
      <div style={{ width: '100%' }}>
        <div className="col-md-12">
            <h4>{title}</h4>
            <br />
          </div>
        <div className="column">
          <div style={{ flex: 1, display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }}>
            <img alt="Phrase" style={{ width: 250, height: 200 }} src={`${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/` + blockData.complement.image.img} />
          </div>
          <div style={{ flex: 1 }}>
            <div className="row">
              <div style={{ flexDirection: 'row', justifyContent: 'center', display: 'flex', width: '100%', paddingLeft: 20 }}>
                <br />
                {shuffledData.map(function (
                  { word, lastDroppedItem },
                  index
                ) {
                  return (
                    <Box
                      name={word}
                      type={word}
                      isDropped={isDropped(word)}
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div className="row">
              <div className="col-md-4" />
              <div className="col-md-8">
              </div>
            </div>
            <div style={{ justifyContent: 'center', backgroundColor: 'white', display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  float: "left",
                  display: "inline",
                }}
              >
                {dustbins.map(function ({ word, lastDroppedItem }, index) {
                  return (
                    <Dustbin
                      accepts={word.replace("[", "")}
                      lastDroppedItem={lastDroppedItem}
                      name={word.replace("[", "")}
                      text={`${splitText(datas, index)}`}
                      onDrop={item => handleDrop(index, item)}
                      key={index}
                    />
                  );
                })}
                <span
                  style={{
                    color: "black",
                    lineHeight: "50px",
                    whiteSpace: "pre-line"
                  }}
                >
                  &nbsp;{lastText(datas).replace(/-/g, "\n")}
                </span>
              </div>
            </div>
          </div>

          <div onClick={() => {
            if (solved) {
              setDroppedBoxNames([])
              setDustbins([])
              props.step('forward')
            } else {
              setSolved(true)
              solve()
            }
          }} className="listen-drag-check-maze">
            {
              solved
                ? 'Done'
                : 'Check'
            }

          </div>
          {
            solved &&
            <div onClick={() => {
              tryAgain()
            }} className="listen-drag-check-maze" style={{ right: 150 }}>
              Try Again
            </div>
          }
        </div>
      </div>
    </div>
  );
};
export default ImagePhraseTypes;
