import React, { useState, useEffect } from "react";
import { setToken, getTranslation, getUser } from "../service/service.js";
import cookie from "react-cookies";
import { Redirect } from "react-router-dom";
import "../assets/templates/ltiLaunchCallback.css";

const LtiLaunchCallback = () => {
    const [loading, setLoading] = useState(false);

    const setLanguage = (user, region) => {
        let language_code;
        let language = [];

        if (
            region !== undefined &&
            user.data.available_languages.some((x) => x.code === region)
        ) {
            language_code = region;
        } else {
            if (
                user.data.region_user &&
                user.data.region_user !== "BRA" &&
                user.data.available_languages[0].code === "en"
            ) {
                language_code = user.data.region_code;
            } else {
                language_code = user.data.available_languages[0].code;
            }
        }
        getTranslation(language_code)
            .then((res) => {
                language = res.data;
                language.selectedLanguage =
                    user.data.available_languages[0].code;
                language.languageCode = language_code;
                console.log("language: ", language);

                localStorage.setItem("languagePack", JSON.stringify(language));
                window.location.reload()
            })
            .catch((err) => {
                console.log("getTranslation error: ", err);
            });

        return language_code;
    };

    useEffect(() => {
        cookie.remove("token_info", { path: "/" });
        localStorage.removeItem("user-info");
        localStorage.removeItem("languagePack");
        sessionStorage.clear();

        let newUrl = window.location.href.replace(/#/g, "");
        newUrl = newUrl.replace(/\/\/+/g, "/");

        const urlParams = new URLSearchParams(newUrl.split("?")[1]);

        const access_token = urlParams.get("access_token");
        const refresh_token = urlParams.get("refresh_token");
        const expires_in = urlParams.get("expires_in");
        const token_type = urlParams.get("token_type");

        const session = {
            access_token: access_token,
            refresh_token: refresh_token,
            expires_in: expires_in,
            token_type: token_type,
        };

        setToken(session).then(() => {
            getUser().then((user) => {
                console.log("user:", user);
                localStorage.setItem("user-info", JSON.stringify(user));
                cookie.save("last_login", new Date().toJSON(), { path: "/" });

                const region = user.data.region_user;
                setLanguage(user, region);
                setLoading(true);
            });
        });
    }, []);

    return (
        <>
            {loading ? (
                <Redirect to="/classes/list" />
            ) : (
                <div className="loading-container">
                    <div className="loading-text">Loading...</div>
                    <div className="loading-spinner"></div>
                </div>
            )}
        </>
    );
};

export default LtiLaunchCallback;
