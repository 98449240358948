import React, { useState, useCallback } from "react";
import Dustbin from "../components/DustbinListenMatch";
import Box from "../components/BoxListenMatch";
import update from "immutability-helper";

const Container = props => {
  const data = props.blockData.content;
  let [solve, setSolve] = useState(false)
  let getTranslation = data.filter(function (arr, index) {
    if (arr.type === "translation") {
      arr.lastDroppedItem = null;
      arr.answer = data[index+1].en;
      return arr;
    }
  });

  let filTranslation = getTranslation.filter(function (el) {
    return el !== null;
  });

  let getDrill = data.filter(function (arr) {
    if (arr.type === "drill") {
      arr.lastDroppedItem = null;
      return arr;
    }
  });

  function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }
  let filDrill = getDrill.filter(function (el) {
    return el != null;
  });

  const [dustbins, setDustbins] = useState(shuffle(filTranslation));
  console.log(dustbins)
  const [boxes] = useState(filDrill);

  const [droppedBoxNames, setDroppedBoxNames] = useState([]);
  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }
  const handleDrop = useCallback(
    (index, item) => {
      const { name } = item;
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] })
      );
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item
            }
          }
        })
      );
    },
    [droppedBoxNames, dustbins]
  );
  const { blockData, contentIndex } = props

  return (
    <div className="default-card container" style={{ width: '100%' }}>
      {blockData.content[contentIndex].type === "instruction" ? (
        <div>
          <h3
            onChange={() => console.log("h3 change")}
            className="instruction-title"
          >
            Instruction
          </h3>
          <h5 className="instruction-text">
            {blockData.content[contentIndex].instruction}
          </h5>
        </div>
      ) : (
        <div>
          <div
            style={{ overflow: "hidden", clear: "both", justifyContent: 'center' }}
            className="row"
          >
            {dustbins.map(({ en, picture, lastDroppedItem, answer }, index) => (
                <Dustbin
                  accepts={answer} // Quando o accepts é igual ao type do component Box, eles se conectam
                  lastDroppedItem={lastDroppedItem}
                  getPicture={picture}
                  name={en.substr(en.length - 1)}
                  onDrop={item => handleDrop(index, item)}
                  key={index}
                  solve={solve}
                />
            ))}
          </div>

          <div
            style={{
              overflow: "hidden",
              clear: "both",
              bottom: "67px",
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {boxes.map(({ en }, index) => (
              // Quando o type é igual ao accepts do component Dustbin, eles se conectam
              <Box name={en} type={en} isDropped={isDropped(en)} key={index} />
            ))}
          </div>
          <div onClick={() => {
            if (solve) {
              props.step('skipForward')
            } else {
              setSolve(true)
            }
          }} className="listen-drag-check-maze">
            {
              solve
                ? 'Done'
                : 'Solve'
            }
          </div>
        </div>
      )}
    </div>
  );
};
export default Container;