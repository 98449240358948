import React, { Component } from "react";
import '../assets/templates/SimpleTextHL.css';

class SimpleTextHL extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      openSecond: false,
      index: 0
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.contentIndex !== this.props.contentIndex){
      this.setState({
        open: false,
      })
    }
  }

  _reset(){
    this.setState({
      open: false
    })
  }

  getHilighted(text){
    let splitText = text.split(/( )/)
    let textObj = []
    let word
    let highLight = false
    for(let i = 0; i < splitText.length; i++){
      word = splitText[i]
      if(word.includes("["))
        highLight = true
      textObj.push({word: highLight ? word.replace("[","").replace("]",""): word, highLight: highLight})
      if(word.includes("]"))
        highLight = false
    }
    return textObj
  }

  searchNextInstruction(){
    const { blockData, contentIndex } = this.props

    for(let i = contentIndex; i < blockData.content.length; i++){
        if(blockData.content[i].type === 'instruction'){
            return i
        }
    }
  }

  searchLastInstruction(){
    const { blockData, contentIndex } = this.props

    for(let i = contentIndex; i > 0; i--){
        if(blockData.content[i].type === 'instruction'){
            return i
        }
    }
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card">
        <div>
            {
                blockData.content[contentIndex].type === 'instruction' ?
                <div>
                    <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                    <h5 className="instruction-text">{ blockData.content[contentIndex].instruction }</h5>
                </div>
                :
                <div>
                    {
                        blockData.content.slice(this.searchLastInstruction(), this.searchNextInstruction()).filter((x) => { return x.type !== "instruction"}).map((item, index)=>{
                            return(
                                <div 
                                ref={(ref)=>{
                                    // scroll to the current div position
                                    if(ref && item.id === blockData.content[contentIndex].id){ window.scrollTo(0, ref.offsetTop - (window.innerHeight / 2)) }
                                }}
                                key={index} className={"simple-highlight-container " + (item.id === blockData.content[contentIndex].id ? "simple-active" : "")} >
                                    {  
                                        this.getHilighted(item.en).map((word, wordIndex)=>{
                                            return(
                                                <h2 key={wordIndex} style={{color: word.highLight ? 'rgb(47, 157, 201)' : (item.id === blockData.content[contentIndex].id ? "white" : "black")}}>
                                                    { word.word } 
                                                </h2>
                                            )
                                        }) 
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                
            }
        </div>
      </div>
    )
  }
}

export default SimpleTextHL;
