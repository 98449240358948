import React, { Component } from "react";
import '../assets/templates/PrePost.css';
import Vimeo from '@u-wave/react-vimeo';

class PrePost extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }



  render() {
    const { blockData, paused } = this.props;

    return (
      <div className="default-card">
        {!blockData.loading ? (
          blockData.videoId && blockData.videoId.length > 0 ? (
            <Vimeo
              paused={paused}
              video={"https://player.vimeo.com/video/" + blockData.videoId}
            />
          ) : (
            <div>No video available</div>
          )
        ) : (
          <div>Loading...</div>
        )}
      </div>
    );
  }
}

export default PrePost;
