import React, { Component } from "react";
import '../assets/templates/ConversationInTwo.css';
import speakingBlue from '../assets/images/speaking-blue.png'
import speakingRed from '../assets/images/speaking-red.png'

class ConversationInTwo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      index: 0,
      currentIntroduction: 0,
      introPos: []
    }
  }

  componentDidMount(){
    const { blockData } = this.props

    // defines the position of the Introduction contents
    let introPos = []
    for(let i = 0; i < blockData.content.length; i++){
        if(blockData.content[i].type === 'instruction'){
            introPos.push({position: i, id: blockData.content[i].id})
        }
    }

    this.setState({ introPos })
  }

  getInstructionIndex() {
    const { contentIndex } = this.props

    // volta o valor 0 se existir somente uma instruction
    if (this.state.introPos.length === 1) return 0

    // volta o valor da posicao do segundo instruction
    for(let j = 0; j < this.state.introPos.length; j++){
        if(contentIndex >= 0 && contentIndex < this.state.introPos[1].position){
            return 0
        }else{
            return this.state.introPos[1].position
        }
    }
  }

  render() {
    const { blockData, contentIndex } = this.props
    
    return (
      <div className="conversation-card">
      {
          blockData.content[contentIndex].type === "instruction" ?
            <div>
                <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                <h5 className="instruction-text">{ blockData.content[contentIndex].instruction }</h5>
            </div>
          :
          <div className="conversation-two">
            {
                blockData.content.slice(this.getInstructionIndex(),contentIndex+1).forEach((item, index) => {
                    if(item.type === 'drill'){
                        return(
                            <div  key={index} className={'conversation-two-word'} >
                                <div className={'conversation-two-bubble'}>
                                    { item.en }
                                </div>
                                <div className="conversation-two-icon-container">
                                    <img className="conversation-two-icon" alt="conversation-two" src={index % 2 !== 0 ? speakingBlue : speakingRed} />
                                </div>
                            </div>
                        )
                    }
                })
            }
          </div>
      }
      </div>
    )
  }
}

export default ConversationInTwo;
