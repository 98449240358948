import React, { Component } from "react";
import "../assets/templates/LeadInText.css";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// Coluna 1
const SortableItem1 = SortableElement(({ value1 }) => (
  <div
    className="illustrated-word"
    style={{
      backgroundColor:
        typeof value1.right1 == "undefined"
          ? "#062E57"
          : value1.right1
            ? "#69f0ae"
            : "#ff5252",
      textAlign: "center",
      color: "white",
      height: "80px",
    }}
  >
    {value1.en.split("/")[0]}
  </div>
));

const SortableList1 = SortableContainer(({ items1 }) => {
  return (
    <ul>
      {items1.map((value1, index) => (
        <SortableItem1
          key={`item-${value1.id}`}
          index={index}
          value1={value1}
        />
      ))}
    </ul>
  );
});

class MatchSentences extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items1: [],
      itemsRamdom1: [],
      checked1: false,
      buttonMsg: "Check",
      solved1: false
    };
  }
  _getitems1() {
    const { blockData } = this.props;
    let items = [];

    items = blockData.complement.respostas
      .filter(a => a.type !== "instruction")
      .map((item, index1) => {
        item.order1 = index1;
        item.right1 = undefined;
        return item;
      });

    this.setState({
      items1: items,
      itemsRamdom1: this._shuffle(items),
      initialitems1: items
    });
  }

  onSortEnd1 = ({ oldIndex, newIndex }) => {
    this.setState(({ items1 }) => ({
      items1: arrayMove(items1, oldIndex, newIndex)
    }));
  };

  _shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  _checkOrder() {
    if (this.state.buttonMsg === 'Done') this.props.step('skipForward')

    if (
      this.state.solved1 ||
      this.state.solved2 ||
      this.state.solved3 ||
      this.state.solved4
    ) {
      return;
    }
    if (this.props.checked) {
      this._solve();
      return;
    }

    let items1 = this.state.items1;
    for (let i = 0; i < this.state.items1.length; i++) {
      if (this.state.items1[i].order1 !== i) {
        items1[i].right1 = false;
      } else {
        items1[i].right1 = true;
      }
    }
    this.props.setforceAudio()
    this.props.setChecked(true)
    this.setState({
      items1: items1,
      checked1: true,
      buttonMsg: "Solve"
    });
  }

  _solve() {
    let initialitems1 = this.state.initialitems1;

    initialitems1 = initialitems1.map(item => {
      item.right1 = true;
      return item;
    });

    initialitems1.sort(function (a, b) {
      return a.order1 - b.order1;
    });
    this.setState({
      items1: initialitems1,
      solved1: true,
      buttonMsg: "Done"
    });
  }
  componentDidMount() {
    this.props.setChecked(false)
    this._getitems1();
  }

  render() {
    const { blockData, contentIndex } = this.props;

    return (
      <div className="default-card container">
        {blockData.content[contentIndex].type === "instruction" ? (
          <div>
            <h3
              onChange={() => console.log("h3 change")}
              className="instruction-title"
            >
              Instruction
            </h3>
            <h5 className="instruction-text">
              {blockData.content[contentIndex].instruction}
            </h5>
          </div>
        ) : (
          <div className="illustrated-container" style={{ height: "auto" }}>
            <div
              className="listen-drag-check-maze"
              onClick={() => {
                this._checkOrder();
              }}
            >
              {this.state.buttonMsg}
            </div>
            <div className="illustrated-words-container drag-drop-list row">
              <div
                className="col-md-6"
                style={{ backgroundColor: "white", paddingTop: "15px" }}
              >
                {blockData.content.map(item =>
                  item.type !== "instruction" ? (
                    <div
                      className="illustrated-word"
                      key={item.id}
                      style={{
                        backgroundColor: "#062E57",
                        color: "white",
                        height: "80px",
                        textAlign: "center"
                      }}
                    >
                      {item.en}
                    </div>
                  ) : null
                )}
              </div>
              <div
                className="col-md-6"
                style={{ backgroundColor: "white", paddingTop: "15px" }}
              >
                <SortableList1
                  items1={this.state.items1}
                  onSortEnd={this.onSortEnd1}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MatchSentences;
