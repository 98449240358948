async function getUser() {
    return JSON.parse(localStorage.getItem("user-info"));
}

export async function getUserId() {
    const userData = await getUser();
    return userData ? userData.id : null;
}

export async function getUserEmail() {
    const userData = await getUser();
    return userData ? userData.email : null;
}