import React from 'react'
import { DragSource } from 'react-dnd'
const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '4rem',
  width: '90%',
  marginRight: '1.5rem',
  marginBottom: '1rem',
  color: 'black',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
  cursor: 'move'

}
export const Box = ({ name, isDragging, connectDragSource }) => {
  const opacity = isDragging ? 0.4 : 1

  return connectDragSource(
    <div style={{ ...style, opacity, backgroundColor: 'white', display: 'flex' }}>{name}</div>
  )
}
export default DragSource(
  props => props.type,
  {
    beginDrag: props => ({ name: props.name }),
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(Box)
