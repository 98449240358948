import React, { Component } from "react";
import '../assets/templates/Carousel.css';

class Carousel extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    // check if the template has the model container
    hasModel() {
        const { blockData } = this.props
        let instructionCount = 0
        for (let i = 0; i < blockData.content.length; i++) {
            if (blockData.content[i].type === 'drill') {
                instructionCount += 1
            }
        }
       
            return instructionCount
        
    }

    contModel() {
        const { blockData } = this.props
        let conteudoModel = ""
        for (let i = 0; i <= this.hasModel(); i++) {
            if (i < 4) conteudoModel = conteudoModel + blockData.content[i].en + '\n'
        }
        console.log(conteudoModel)
        return conteudoModel
    }

    render() {
        const { blockData, contentIndex } = this.props;
        return (
            <div className="default-card">
                {
                    blockData.content[contentIndex].type === 'instruction' ?
                        <div>
                            <h3 onChange={() => console.log('h3 change')} className="instruction-title">Instruction</h3>
                            <h5 className="instruction-text">{blockData.content[contentIndex].instruction}</h5>
                        </div>
                        :
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                            <div className="carousel-sentence">
                                {
                                    this.hasModel() > 0

                                        ? contentIndex > 0 && contentIndex <= this.hasModel()

                                            ? <h2> {blockData.content[contentIndex].en} </h2>

                                            : contentIndex > this.hasModel()

                                                ?
                                                <div>
                                                    <div className="Card-itens-front">
                                                        <img className="carousel-img"
                                                            alt="Carousel"
                                                            src={`${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/${blockData.complement.boxImage[contentIndex - (this.hasModel()+1)].img}`}
                                                        />
                                                    </div>
                                                    <div>
                                                        <h2> {blockData.content[contentIndex].en} </h2>
                                                    </div>
                                                </div>
                                                : null // esconde o texto ao mostrar somento o modelo
                                        : <h2> {blockData.content[contentIndex].en} </h2> // mostra somente o texto quando nao tem modelo
                                }
                            </div>
                            {
                                this.hasModel() < contentIndex && // mostra o modelo somente quando ele existe
                                <div className="carousel-model">
                                    <div className="carousel-model-title">
                                        Model
                                    </div>
                                    <div className="carousel-model-text">
                                        {this.contModel()}
                                    </div>
                                </div>

                            }


                        </div>
                }
            </div>
        )
    }
}

export default Carousel
