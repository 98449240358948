import React, { Component } from "react";

class ImageAudio extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  getImageUrl(image){
      return `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/` + image 
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card">
            {
                blockData.content[contentIndex].type === 'instruction' ?
                <div>
                    <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                    <h5 className="instruction-text">{ blockData.content[contentIndex].instruction }</h5>
                </div>
                :
                <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
                    <img 
                      alt="Audio"
                      style={{ width: '90%', alignSelf: 'center', maxWidth: "400px" }} 
                      src={this.getImageUrl(blockData.complement.img.img)} />
                </div>
                
            }
      </div>
    )
  }
}

export default ImageAudio
