import React, { Component } from "react";
import '../assets/templates/LinkingSounds.css';

class LinkingSounds extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      index: 0
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.contentIndex !== this.props.contentIndex){
      this.setState({
        open: false,
      })
    }
  }

  _reset(){
    this.setState({
      open: false
    })
  }

  getHilighted(text){
    let splitText = text.split(/( )/)
    let textObj = []
    for(let i = 0; i < splitText.length; i++){
        if(splitText[i].includes("_")){
            textObj.push({word: splitText[i].replace(/(_)/g," "), highLight: true})
        }else{
            textObj.push({word: splitText[i], highLight: false})
        }
    }
    console.log('textObj: ', textObj)
    return textObj
  }

  render() {
    const { data } = this.props;
    return (
      <div className="default-card">
        <div>
            {
                data.type === 'instruction' ?
                <div>
                    <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                    <h5 className="instruction-text">{ data.instruction }</h5>
                </div>
                :
                <div>   
                    {
                        <div 
                        className="linking-sounds-container">
                            {  
                                this.getHilighted(data.en).map((item, wordIndex)=>{
                                    return(
                                        <h2 key={wordIndex} style={{ 
                                            color: item.highLight ? 'rgb(47, 157, 201)' : 'white',
                                            borderBottom: item.highLight ? '2px solid rgb(47, 157, 201)' : 'none'
                                            }}>
                                            { item.word }
                                        </h2>
                                    )
                                }) 
                            }
                        </div>
                    }
                </div>
                
            }
        </div>
      </div>
    )
  }
}

export default LinkingSounds;
