import axios from 'axios'
import qs from 'qs'
import cookie from 'react-cookies'


const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
const API_URL_SPEECHACE = process.env.REACT_APP_API_URL_SPEECHACE;
const API_URL = process.env.REACT_APP_API_URL
const API_URL_PROGRESS_TRACKER = process.env.REACT_APP_API_URL_PROGRESS_TRACKER

const cmsNoPrefix = axios.create({
  baseURL: `${API_URL}/`,
  timeout: 20000
})

const cms = axios.create({
  baseURL: `${API_URL}/wizme_api/v1/`,
  timeout: 25000,
})

const apiSpeechace = axios.create({
  baseURL: `${API_URL_SPEECHACE}/v1/speechace`,
  timeout: 25000,
});

const progressTracker = axios.create({
  baseURL: `${API_URL_PROGRESS_TRACKER}/`,
  timeout: 25000,
})

export function createUsage(data){
  return progressTracker({
    method: "POST",
    url: "/usage/cupgov",
    data: data,
  });
}

export function updateUsage(data){
  return progressTracker({
    method: "PATCH",
    url: "/usage/cupgov",
    data: data,
  });
}

export function getUsage(userId, lessonId){
  return progressTracker({
    method: "GET",
    url: `/usage/cupgov/${userId}/${lessonId}`,
  });
}

export function getLanguagePack(){
  let data = localStorage.getItem('languagePack')

  return data == null ? {} : JSON.parse(data)
}

export async function loginManager(data) {
  try {
    const session = await login(data)

    setToken(session)
    cookie.save('last_login', new Date().toJSON(), { path: '/' })

    let user = await getUser()

    localStorage.setItem('user-info', JSON.stringify(user))

    return {
      token: session,
      user
    }
  } catch (error) {
    throw error
  }
}

export function getCodeForQr() {
    return cms({
      method: 'GET',
      url: '/qrcode/hash/'
    })
  }

export async function setToken(data) {
    try {
        let stringifiedToken = JSON.stringify(data)
        setHeader(data.token_type, data.access_token)
        cookie.save('token_info', stringifiedToken, { path: '/' })
        return data
    } catch (e) {
        throw { status: 'storage', message: 'Problema ao armazenar o token.' }
    }
}

// get pre e post video do cadastro do CMS - inicio
export async function getPrePostVideo(lessonId) {
  return cms({
    method: 'GET',
    url: `/lessons/${lessonId}/catchupvideo/`,
  })
}

// Get content for the lesson
export async function getLessonContent(lessonId, params = {}) {
  return cms({
    method: "GET",
    url: `/lessons/${lessonId}/backbone/`,
    params: { ...params, is_catchup: true },
  });
}

export function getLessonList() {
  return cms({
    method: 'GET',
    url: `/catchup/lessons/`
  })
}

export async function finishLesson() {
  return cms({
    method: 'POST',
    url: '/catchup/done/',
    data: {
      "catchUPID": 37,
      "licaoID": 74,
      "percentualAcerto": 90.5,
      "tentativas": 10,
      "dataHora": "2019-08-26 10:20:30"
    }
  })
}

cms.interceptors.request.use(config => {
  let sessionToken = cookie.load('token_info')
  if (sessionToken){
      const tokenType = sessionToken.token_type
      const accessToken = sessionToken.access_token
      config.headers['Authorization'] = tokenType + " " + accessToken
  }
  return config
}, error => Promise.reject(error))

cms.interceptors.response.use(
    async response => {
    //   if (response.data.detail === 'Invalid token') {
    //     console.log('response.data.detail === Invalid token')
    //     getStore().dispatch({ type: 'Navigation/Custom/RESET' })
    //     return Promise.reject({ status: 'invalid-token', message: 'Token Inválido.' })
    //   }

    let sessionToken = cookie.load('token_info')
    if (sessionToken){
        const tokenType = sessionToken.token_type
        const accessToken = sessionToken.access_token
        cms.defaults.headers.common['Authorization'] = tokenType + " " + accessToken
    }

      return response.data
    },
    async exception => {
      const { request = {} } = exception
      let error = { status: -1, message: 'Problemas com o servidor. Tente mais tarde. (1)' }

      switch (request.status) {
        case 400:
          if (exception.response.data.audio)
            error = { status: 400, message: exception.response.data.audio[0] }
          else error = { status: 400, message: 'Usuário ou senha incorreta.' }
          break

        case 401:
          return refreshToken()
            .then(setToken)
            .then(data => {
              exception.config.headers['Authorization'] = `${data.token_type} ${data.access_token}`
              return cms.request(exception.config)
            })

        case 404:
          error = { status: 404, message: 'Conteudo não encontrado' }
          break

        case 500:
          error = { status: 500, message: 'Problemas com o servidor. Tente mais tarde. (2)' }
          break

        default:
          break
      }

      return Promise.reject(error)
    }
  )

export function setHeader(type, token) {
    if (!token) {
        throw { status: 'token', message: 'Token não definido' }
    }
    console.log('authorization: ', `${type} ${token}`)
    cms.defaults.headers.common['Authorization'] = `${type} ${token}`
}

export function getUser(params = {}) {
    return cms({
      method: 'GET',
      url: '/me/',
      params: { ...params }
    })
  }

export function login(data) {
  return cms({
    method: 'POST',
    url: '/oauth2/token/',
    data: qs.stringify({
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      ...data
    })
  })
}

/**
 * Faz a requisição dos livros.
 * @param {Object} params Paramêtros adicionais para a requisição, como o token
 */
export function getCourses(params = {}) {
  return cms({
    method: 'GET',
    url: '/courses/',
    params: { ...params, is_catchup: true }
  })
}

/**
 * Faz a requisição das lições de um determinado curso.
 * @param {string} id Id do curso
 * @param {Object} params Paramêtros adicionais para a requisição, como o token.
 */
export function getLessons(id, params = {}) {
  return cms({
    method: 'GET',
    url: `/courses/${id}/only-lessons-data/`,
    params: { ...params }
  })
}

export function endLesson(data) {
  return cms({
    method: 'POST',
    url: '/catchup/done/',
    data: data
  })
}

export function getScoreDataSpeechace(data) {
  return apiSpeechace({
    method: "POST",
    url: "/score",
    data: data,
  });
}

export function sendDataSpeechaceMetrics(data) {
  return apiSpeechace({
    method: "POST",
    url: "/metric",
    data: data,
  });
}

export function getScoreMetricsSpeechace(user_id, id_exercise_question) {
  return apiSpeechace({
    method: "GET",
    url: `/metric/${user_id}/${id_exercise_question}`,
  });
}

export function updateScoreMetricsSpeechace(data) {
  return apiSpeechace({
    method: "PUT",
    url: `/metric`,
    data: data,
  });
}

export function getTranslation(language) {
  return cmsNoPrefix({
    method: 'POST',
    url: `taas/api/v1/wizme/${language}/`,
    data: { "auth_key": "Vn8zUEV/ST8hVmhdXyJJJUQvJntN" }
  })
}

export function refreshToken() {
  return getToken().then(token =>
    login({ grant_type: 'refresh_token', refresh_token: token.refresh_token })
  )
}

export async function getToken() {
    try {
      let stringifiedToken = cookie.load('token_info')
      if (stringifiedToken === null) throw { status: 'storage', message: 'Token não existe.' }
        let token = stringifiedToken
      setHeader(token.token_type, token.access_token)
      return token
    } catch (e) {
      throw { status: 'storage', message: e.status === 'storage' ? e.message : 'Problema ao recuperar o token.' }
    }
  }
