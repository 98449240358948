import React, { Component } from "react";
import "../assets/templates/LeadInText.css";

class ListenAndChoose extends Component {
  constructor(props) {
    super(props);

    this.state = {
      borderColorBool: false,
      data: [],
      buttonMsg: "Check"
    };
  }

  componentDidMount() {
    this.getwords();
  }

  componentDidUpdate(props){
    if(props.blockData.block_id !== this.props.blockData.block_id){
      this.getwords();
    }
  }

  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  getwords() {
    let dataYes = this.props.blockData.complement.yes || this.props.blockData.complement.Yes;
    let dataNo = this.props.blockData.complement.no || this.props.blockData.complement.No;
    let arrYes = [];
    let arrNo = [];
    let concatObj = [];
    for (let i = 0; i < dataYes.length; i++) {
      arrYes.push({
        value: dataYes[i].img,
        status: "yes",
        originalColor: "green",
        selectedColor: "black",
        clicked: false
      });
    }
    for (let j = 0; j < dataNo.length; j++) {
      arrNo.push({
        value: dataNo[j].img,
        status: "no",
        originalColor: "red",
        selectedColor: "black",
        clicked: false
      });
    }
    concatObj = arrNo.concat(arrYes);
    this.setState({ data: this.shuffle(concatObj) });
  }

  changeColor(item) {
    let newData = this.state.data;
    newData.forEach(function(value) {
      if (item === value.value) {
        value.clicked = !value.clicked;
      }
    });

    this.setState({ data: newData });
  }

  _checkOrder() {
    let data = this.state.data;
    data.forEach(function(value) {
      value.selectedColor = value.originalColor;
      value.clicked = true;
    });

    this.setState({ data: data, buttonMsg: "Done" });
    if(this.state.buttonMsg === 'Done') {
      this.setState({ buttonMsg: "Check" });
      this.props.step('forward', true)
    }
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card container">
        {blockData.content[contentIndex].type === "instruction" ? (
          <div>
            <h3 className="instruction-title">
              Instruction
            </h3>
            <h5 className="instruction-text">
              {blockData.content[contentIndex].instruction}
            </h5>
          </div>
        ) : (
          <div>
            <div
              className="listen-drag-check-maze"
              onClick={() => {
                this._checkOrder();
              }}
            >
              {this.state.buttonMsg}
            </div>
            <div className="row" style={{ paddingLeft: "10%" }}>
              {this.state.data.map(item => (
                <div className="col-md-6" style={{ padding: "10px" }} key={item.value}>
                  <img
                    alt="ListenAndChoose"
                    src={`${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/${item.value}`}
                    className="leadInTextImg"
                    onClick={() => {
                      this.changeColor(item.value);
                    }}
                    style={{
                      border: "solid 10px",
                      borderColor: item.clicked ? item.selectedColor : "white",
                      width: "auto",
                      height: "250px"
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ListenAndChoose;
