import React, { Component } from "react";
import '../assets/templates/FluencyInPairs.css';

class FluencyInPairs extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  // check if the template has the model container
  hasModel(){
    const { blockData } = this.props
    let instructionCount = 0

    for(let i = 0; i < blockData.content.length ; i++){
    if (blockData.content[i].type === 'instruction') instructionCount += 1
    }
    
    if (instructionCount >= 0) {
        return true
    }else{
        return false
    }
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card">
            {
                blockData.content[contentIndex].type === 'instruction' ?
                <div>
                    <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                    <h5 className="instruction-text">{ blockData.content[contentIndex].instruction }</h5>
                </div>
                :
                <div style={{display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>   
                    <div className="fluency-sentence">
                        {
                            this.hasModel() 
                                ? contentIndex !== 1 
                                    ? <h2> { blockData.content[contentIndex].en } </h2>
                                    : null // esconde o texto ao mostrar somento o modelo
                                : <h2> { blockData.content[contentIndex].en } </h2> // mostra somente o texto quando nao tem modelo
                        }
                    </div>
                    {
                        this.hasModel() && // mostra o modelo somente quando ele existe
                        <div className="fluency-model">
                            <div className="fluency-model-title">
                                Model
                            </div>
                            <div className="fluency-model-text">
                                { blockData.content[1].en }
                            </div>
                        </div>
                    }
                </div>
                
            }
      </div>
    )
  }
}

export default FluencyInPairs
