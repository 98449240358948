import React from 'react'
import { DropTarget } from 'react-dnd'
const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '200px',
  width: '200px',
  marginRight: '15px',
  marginBottom: '1rem',
  color: 'black',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
}
export const Dustbin = ({
  getPicture,
  name,
  connectDropTarget,
  lastDroppedItem,
  solve
}) => {
  let backgroundColor = 'white'
  if (lastDroppedItem) {
    backgroundColor = '#003057'
  }

  return connectDropTarget(
    <div style={{ ...style, backgroundColor }}>
      <div className='content' style={{ position: 'relative' }}>
        {
          solve &&
          <div style={{ position: 'absolute', top: 10, right: 10, borderRadius: 50, backgroundColor: 'rgba(255,255,255,0.5)' }}> {name} </div>
        }
        <img src={`${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/${getPicture}`} style={{ width: '190px', height: '190px' }} />


      </div>
    </div>
  )
}
export default DropTarget(
  props => props.accepts,
  {
    drop(props, monitor) {
      props.onDrop(monitor.getItem())
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(Dustbin)