import React from 'react'
import { DragSource } from 'react-dnd'
export const Box = ({ name, isDropped, isDragging, connectDragSource }) => {
  const opacity = isDragging ? 0.4 : 1
  const backColor = isDropped ? 'transparent':  'white' 
  return connectDragSource(
    <div className="box-text-item" style={{ opacity, backgroundColor: backColor}}>{isDropped ? '': name}</div>,
  )
}
export default DragSource(
  props => props.type,
  {
    beginDrag: props => ({ name: props.name }),
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(Box)