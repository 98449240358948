import React, { Component } from "react";
import '../assets/templates/ListenDrag.css';
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move';

const SortableItem = SortableElement(({ value }) => <div className="illustrated-word illustrated-word-hover" style={{
    backgroundColor: typeof value.right == "undefined"
        ? 'white'
        : value.right
            ? '#69f0ae'
            : '#ff5252',
    margin: '0px 10px ',
    fontSize: 22
}} >{value.name}</div>);

const SortableList = SortableContainer(({ items }) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {items.map((value, index) => (
                <SortableItem axis={'xy'} key={`item-${value.order}`} index={index} value={value} />
            ))}
        </div>
    );
});

class PutInOrderWord extends Component {
    constructor(props) {
        super(props)

        this.state = {
            items: [],
            checked: false,
            buttonMsg: 'Check',
            solved: false
        }
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }

        return array
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.blockData.num !== this.props.blockData.num) {
            this._getItems()
        }
    }

    _getItems() {
        const { blockData } = this.props
        let items = []
        for (let i = 0; i < blockData.complement.palavras.length; i++) {
            items.push({ name: blockData.complement.palavras[i], order: i })
        }

        this.setState({
            items: this.shuffleArray(items),
            initialItems: items
        })
    }

    _checkOrder() {
        let items = this.state.items
        if (this.state.solved) {
            this.setState({
                items,
                checked: false,
                solved: false,
                buttonMsg: "Check"
            })
            this.props.step('forward')
            return;
        }
        if (this.state.checked) {
            this._solve()
            return
        }

        for (let i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].order !== i) {
                items[i].right = false
            } else {
                items[i].right = true
            }
        }
        this.setState({
            items,
            checked: true,
            buttonMsg: 'Solve'
        })
    }

    _solve() {
        let items = this.state.items

        items = items.sort((a, b) => { return a.order - b.order }) // sort by order number
        items = items.map(item => { // set all answers as right
            item.right = true
            return item
        })

        this.setState({
            items,
            solved: true,
            buttonMsg: 'Done'
        })
    }

    componentDidMount() {
        this._getItems()
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        document.body.style.cursor = ""
        this.setState(({ items }) => ({
            items: arrayMove(items, oldIndex, newIndex),
        }))
    }


    getImageUrl(image) {
        return `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/` + image
    }

    render() {
        const { blockData, contentIndex } = this.props;
        return (
            <div className="default-card">
                {
                    blockData.content[contentIndex].type === 'instruction' ?
                        <div>
                            <h3 className="instruction-title">Instruction</h3>
                            <h5 className="instruction-text">{blockData.content[contentIndex].instruction}</h5>
                        </div>
                        :
                        <div className="illustrated-container" style={{}}>
                            <div className="listen-drag-check" onClick={() => { this._checkOrder() }}>
                                {
                                    this.state.buttonMsg
                                }
                            </div>
                            <div className="illustrated-words-container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <SortableList axis='xy'
                                    onSortStart={() => { document.body.style.cursor = "grabbing" }}
                                    items={this.state.items} onSortEnd={this.onSortEnd} />
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default PutInOrderWord;
