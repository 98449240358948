import React, { useState, useCallback } from "react";
import Dustbin from "../components/RoleDustbinMatch";
import Box from "../components/RoleBoxMatch";
import update from "immutability-helper";
import '../assets/templates/DialogRolePlay.css';

const Container = props => {
  const data = props.blockData.content[0].sentences;

  if(typeof props.blockData.content[0].sentences[0].order == "undefined"){
    console.log('passou no if')
    for ( let y = 0; y < props.blockData.content[0].sentences.length; y++){
      console.log('ORDER BY')
      props.blockData.content[0].sentences[y].order = y.toString()
      props.blockData.content[0].sentences[y].orderBy = Math.round(Math.random() * 10)
    }
  }


  let getTranslation = data.map(function(arr) {
      arr.lastDroppedItem = null;
      return arr;
    
  });

  let filTranslation = getTranslation.filter(function(el) {
    return el != null;
  });

  const [dustbins, setDustbins] = useState(filTranslation);

  const [boxes] = useState(filTranslation.reverse());

  const [droppedBoxNames, setDroppedBoxNames] = useState([]);

  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }

  const handleDrop = useCallback(
    (index, item) => {
      console.log(index, " item: ", item)
      const { name } = item;
      setDroppedBoxNames(
        update(droppedBoxNames, name || name === 0 ? { $push: [index] } : { $push: [] })
      );
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item
            }
          }
        })
      );
      console.log('droppedBoxNames: ', droppedBoxNames)
    },
    [droppedBoxNames, dustbins]
  );
  return (
    <div className="default-card dialog-main-container ">
    {
      props.blockData.content[props.contentIndex].type === 'dialog' ?
      <div style={{ textAlign: 'center' }}>
        <h2>Dialogue Practice</h2>
        <h4 style={{ marginTop: 30 }}>{ props.blockData.content[props.contentIndex].title }</h4>
      </div>
      :
      <div className="default-card dialog-main-container ">
        <div className="dialog-conversation-container" >
          {
            droppedBoxNames.length === 0 ?
            <div className="sentence-empty">
              Drag the sentences here
            </div>
            :
            props.blockData.content[0].sentences.slice(0,droppedBoxNames.length).map((item, index) => {
                  return(
                      <div  key={index} className={'dialog-word'} >
                          <div className={'conversation-two-bubble'}>
                              { item.text }
                          </div>
                          <div className="dialog-icon-container">
                            { item.character }
                          </div>
                      </div>
                  )
          })
          }
          <div className="draggable-dialog">
            {dustbins.forEach(({ type, en, picture, lastDroppedItem, sound, order }, index) => {
              if (!isDropped(index)){
                return (
                <div key={index} className="dialog-drop-content">
                  <Dustbin
                    accepts={index.toString()}
                    lastDroppedItem={lastDroppedItem}
                    getPicture={picture}
                    name={index.toString()}
                    onDrop={(item) => {
                      console.log('item: ', item)
                      handleDrop(index, item)
                    }}
                    key={index}
                  />
                </div>
              )
              }})}
          </div>
      </div>

    <div className="dialog-responses-container">
      {boxes.map(({ en, type, sound, text, order, orderBy }, index) => (
        <Box name={text} type={index.toString()} orderBy={orderBy} isDropped={isDropped(index)} key={index} />
      ))}
    </div>
    </div>
    }
    
    </div>
  );
};
export default Container;