import React, { Component } from "react";
import "../assets/header.css";
import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faQuestion, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import cookie from 'react-cookies'
import ReactCountryFlag from "react-country-flag"
import SelectLanguage from "../containers/selectLanguage";
import { getLanguagePack } from '../service/service'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loggedIn: true,
      modalOpen: this.shouldOpenModal(),
      languagePackage: getLanguagePack()
    }
  }

  _getMessage(message){
    return this.state.languagePackage[message] ? this.state.languagePackage[message] : message
  }

  closeModal(){
    this.setState({ modalOpen: false })
  }

  openModal(){
    this.setState({ modalOpen: true })
  }

_logout(){
  cookie.remove('token_info', { path: '/' })
  this.setState({
    loggedIn: false
  })
}

_redirectTo(url){
  window.location.href = url
}

getLanguageFlag(code){
  switch(code){
      case 'en':
          return 'US'

      default:
          return code
  }
}

shouldOpenModal(){
  const user = JSON.parse(localStorage.getItem('user-info')) || { data: { available_languages: [] } }
  const language = JSON.parse(localStorage.getItem('languagePack')) || {}

  if(!language.selectedLanguage && user.data.available_languages.length > 1){
    return true
  }else{
    return false
  }

}

showButton(){
  const user = JSON.parse(localStorage.getItem('user-info')) || { data: { available_languages: [] } }

  if(user.data.available_languages.length > 1){
    return true
  }else{
    return false
  }

}

render(){
  if(!this.state.loggedIn){
    return(
      <Redirect to={{ pathname: '/' }}/>
    )
  }

  const language = JSON.parse(localStorage.getItem('languagePack')) || {}

  return (
    
    <header className="header">
      { 
        this.state.modalOpen &&
        <SelectLanguage
          closeModal={() => this.closeModal()}
        /> 
      }
      <nav className="navbar navbar-expand-lg navbar-light navbar-adjust">
        <div className="back-header">
          {
            this.props.menuLanguage && this.showButton() &&
            <div onClick={() => this.openModal()} style={{ cursor: 'pointer' }}>
              <ReactCountryFlag 
              countryCode={this.getLanguageFlag(language.selectedLanguage)} 
              style={{ fontSize: 50, marginRight: 15 }} 
              />
              <span style={{ color: 'white' }}>
                Change Course
              </span>
            </div>
          }
          {
          this.props.exercise &&
            <Link
            to={`/classes/list`}>
              <FontAwesomeIcon className="icon-step" icon={ faChevronLeft } />
            Back
            </Link>
          }
          </div>
        
          <div className="box-help">
           <h1 className='online-repl-title'>Catch Up</h1>
          </div>
          <div className="back-header">
          <button 
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              display: 'flex',
              flexDirection: 'row'
            }}
            onClick={() => this._redirectTo("https://franquias.wizard.com.br/wiztab")}>
            <p
              style={{
                color: 'white',
                fontSize: '1em',
                marginRight: '10px',
                marginTop: '3px'
              }}>
              FAQ
            </p> 
            <FontAwesomeIcon className="icon-step icon-faq" icon={ faQuestion } /> 
          </button>
          <button 
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              display: 'flex',
              flexDirection: 'row'
            }}
            onClick={() => this._logout()}>
            <p
              style={{
                color: 'white',
                fontSize: '1em',
                marginRight: '10px',
                marginTop: '3px'
              }}>
              Logout
            </p> 
            <FontAwesomeIcon className="icon-step" icon={ faSignOutAlt } /> 
          </button>
        </div>
      </nav>
    </header>
  )
}

}


export default Header;
