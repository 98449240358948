import React, { Component } from "react";
import '../assets/templates/SimpleTextHL.css';

class SentencesList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      openSecond: false,
      index: 0
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.contentIndex !== this.props.contentIndex){
      this.setState({
        open: false,
      })
    }
  }

  _reset(){
    this.setState({
      open: false
    })
  }

  getHilighted(text){
    let splitText = text.split(/( )/)
    let textObj = []
    for(let i = 0; i < splitText.length; i++){
        if(splitText[i].includes("[") || splitText[i].includes("]") ){
            textObj.push({word: splitText[i].replace("[","").replace("]",""), highLight: true})
        }else{
            textObj.push({word: splitText[i], highLight: false})
        }
    }
    return textObj
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card">
        <div>
            {
                blockData.content[contentIndex].type === 'instruction' ?
                <div>
                    <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                    <h5 className="instruction-text">{ blockData.content[contentIndex].instruction }</h5>
                </div>
                :
                <div>   
                    {
                        blockData.content.filter((x) => { return x.type !== "instruction"}).map((item, index)=>{
                            return(
                                <div 
                                ref={(ref)=>{
                                    // scroll to the current div position
                                    if(ref && item.id === blockData.content[contentIndex].id){ window.scrollTo(0, ref.offsetTop - (window.innerHeight / 2)) }
                                }}
                                key={index} className={"simple-highlight-container " + (item.id === blockData.content[contentIndex].id ? "simple-active" : "")} >
                                    {  
                                        this.getHilighted(item.en).map((word, wordIndex)=>{
                                            return(
                                                <h2 key={wordIndex} style={{color: word.highLight ? 'rgb(47, 157, 201)' : (item.id === blockData.content[contentIndex].id ? "white" : "black")}}>
                                                    { word.word } 
                                                </h2>
                                            )
                                        }) 
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                
            }
        </div>
      </div>
    )
  }
}

export default SentencesList;
