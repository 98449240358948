import React, { Component } from "react";
import Collapsible from "react-collapsible";
import '../assets/templates/DoubleSentence.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons'


class ListenSpeak extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      openSecond: false,
      index: 0
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.contentIndex !== this.props.contentIndex){
      this.setState({
        open: false,
      })
    }
  }

  _reset(){
    this.setState({
      open: false
    })
  }

  render() {
    const { data } = this.props;
    // const newLesson = parseInt(block) + 1;
    return (
      <div className="default-card">
      <div>
      </div>
            {
              data.type === 'instruction'
                ?
                <div>
                  <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                  <h5 className="instruction-text">{ data.instruction }</h5>
                </div>
                :
                <div>
                    <h2>
                    <Collapsible 
                    triggerTagName="div" 
                    className="collapse-opened" 
                    trigger={data.en}
                    open={this.state.open}
                    handleTriggerClick={()=>this.setState({ open: !this.state.open })}
                    onClosing={()=>{this.setState({ isClosing: true })}}
                    onOpening={()=>{this.setState({ isClosing: false })}}
                    >
                    { !this.state.isClosing ? data.pt : null }
                    </Collapsible>
                  </h2>
                  <div className="highlight-icon-container">
                        <div className="highlight-icon" onClick={()=>{this.setState({ open: !this.state.open })}}>
                            <FontAwesomeIcon className="icon-step" icon= {this.state.open === false ?  faArrowAltCircleDown     :  faWindowClose  } />
                        </div>
                    </div>
                </div>
            }
          
      </div>
    )
  }
}

export default ListenSpeak;
