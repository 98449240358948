import React, { Component } from "react";
import { getTranslation } from "../service/service.js"
import SelectIcon from '../assets/images/select-language.png'
import ReactCountryFlag from "react-country-flag"


class SelectLanguage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            languages: [],
            isLoading: false,
            selectedLanguage: null,
            languageError: false
        }
    }

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user-info')) || { data: { available_languages: [] } }
        var available_languages = user.data.available_languages
        this.setState({
            languages: available_languages.map(item => {
                item.selected = false
                return item
            }),
            region: user.data.region_user,
            region_code: user.data.region_code
        })
    }



    _selectLanguage(index) {
        let languages = this.state.languages

        languages = languages.filter(item => {
            item.selected = false
            return item
        })

        languages[index].selected = true

        this.setState({
            languages,
            selectedLanguage: languages[index].code
        })

    }

    getLanguageFlag(code) {
        switch (code) {
            case 'en':
                return 'US'

            default:
                return code
        }
    }

    _setLanguage() {
        let language = []
        this.setState({ isLoading: true })

        let language_code

        if (this.state.region && this.state.region !== 'BRA' && this.state.selectedLanguage === 'en') {
            language_code = this.state.region_code
        } else {
            language_code = this.state.selectedLanguage
        }

        getTranslation(language_code).then(res => {
            language = res.data
            language.selectedLanguage = this.state.selectedLanguage
            language.languageCode = language_code
            localStorage.setItem('languagePack', JSON.stringify(language))
            window.location.reload()

        }).catch(err => {
            console.log('getTranslation error: ', err)
            this.setState({ languageError: true })
        }).finally(() => {
            this.setState({ isLoading: false })
        })

    }

    onErrorCloseModal() {
        let language = {}
        language.selectedLanguage = this.state.selectedLanguage
        localStorage.setItem('languagePack', JSON.stringify(language))

        window.location.reload()
    }


    render() {

        return (
            <div className="select-language-backdrop">
                <div className="select-language-container">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 25 }}>
                        <img alt="alt" src={SelectIcon} style={{ height: 100, width: 100, filter: 'contrast(0.3)' }} />
                        <span style={{ fontSize: 20, textAlign: 'center', color: 'rgb(117,117,117)', marginTop: 10, marginBottom: 20 }}>
                            Selecione o curso
                        </span>
                    </div>
                    <div className="language-item-container">
                        {
                            this.state.languages.map((item, index) => {
                                return (
                                    <div className="language-item" key={index} style={item.selected ? { backgroundColor: 'deepskyblue' } : {}} onClick={() => { this._selectLanguage(index) }}>
                                        <ReactCountryFlag countryCode={this.getLanguageFlag(item.code)} style={{ fontSize: 50, marginRight: 15 }} />
                                        {item.name}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <button disabled={!this.state.selectedLanguage} className="select-language-button" onClick={() => this._setLanguage()}>
                        {
                            this.state.loading &&
                            <div className="spinner-border home-loading" role="status" style={{ position: 'absolute', right: 15 }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                        }
                        <span style={{ color: 'white' }}>
                            {
                                this.state.isLoading ?
                                    <div>
                                        Carregando idioma...
                                    </div>
                                    :
                                    'Selecionar'

                            }
                        </span>
                    </button>
                    {
                        this.state.languageError &&
                        <div style={{ paddingBottom: 20, color: 'red' }}>
                            Erro ao selecionar idioma. <span onClick={() => this.onErrorCloseModal()} style={{ color: 'indianred', cursor: 'pointer' }}>(Fechar)</span>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default SelectLanguage;
