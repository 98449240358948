import React, { Component } from "react"
import './footer.css'
import WizardLogo from '../assets/images/wizard-logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faPauseCircle, faClock } from '@fortawesome/free-regular-svg-icons'
import { faStepForward, faStepBackward } from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player'
import { getLanguagePack } from '../service/service'

class Footer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      delay: 1000,
      languagePackage: getLanguagePack()
    }
    this._togglePlay = this._togglePlay.bind(this)
  }


  _getMessage(message) {
    return this.state.languagePackage[message] ? this.state.languagePackage[message] : message
  }

  // função que pausa ou inicia o audio player
  _togglePlay(){
    this.props.togglePlay(this.props.playAudio)
  }

  _replay() {
    this.player.seekTo(0)
    this.props.togglePlay(true)
  }

  _resetPlayer() {
    this.player.seekTo(0)
  }

  async _keepPlaying() {
    const { keepPlaying } = this.props
    if (keepPlaying) {
      setTimeout(
        function () {
          if (this.props.playAudio) {
            this.props.step('forward')
          }
        }
          .bind(this),
        this.state.delay
      )
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.contentIndex !== this.props.contentIndex && prevProps.audioUrl === this.props.audioUrl){
      this._togglePlay()
      setTimeout(
        function () {
          this._togglePlay()
        }
          .bind(this),
        1000
      )
    }
  }

  render() {
    let { audioUrl, disablePlay } = this.props
    return (
      <div className="footer">
        <ReactPlayer
          url={audioUrl}
          onEnded={()=>{ this._keepPlaying() }}
          playing={this.props.playAudio}
          width={0}
          height={0}
          ref={(ref) => this.player = ref}
        />
        <img alt='footer' className="footer-logo" src={WizardLogo} />
        <div />
        <div className="time-interval">
        <FontAwesomeIcon className="icon-clock" icon={ faClock } /> {this._getMessage('tempo de intervalo')}: 
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div 
          className="select-speed" 
          onClick={() => this.setState({ delay: 2500 })}
          style={{ color: this.state.delay === 2500 ? 'rgb(86, 189, 230)' : 'white' }}>
            3x
          </div>
          <div 
          className="select-speed" 
          onClick={() => this.setState({ delay: 1000 })}
          style={{ color: this.state.delay === 1000 ? 'rgb(86, 189, 230)' : 'white' }}>
            2x
          </div>
          <div 
          className="select-speed" 
          onClick={() => this.setState({ delay: 300 })}
          style={{ color: this.state.delay === 300 ? 'rgb(86, 189, 230)' : 'white' }}>
            1x
          </div>
        </div>
        </div>
        <div className="footer-player">
          <div className="icon-step-container" onClick={() => { this.props.step('backward', true) }} >
            <FontAwesomeIcon className="icon-step" icon={faStepBackward} />
          </div>
          <div className="icon-play-container" onClick={() => { !disablePlay && this._togglePlay() }}>
            {
              this.props.playAudio
                ? <FontAwesomeIcon className="icon-play" icon={ faPauseCircle } />
                : <FontAwesomeIcon className="icon-play" icon={ faPlayCircle } />
            }
          </div>
          <div className="icon-step-container" onClick={() => { this.props.step('forward', true) }} >
            <FontAwesomeIcon className="icon-step" icon={faStepForward} />
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;
