import React, { useState, useRef } from "react";
import "../assets/templates/SimpleSentenceVr.css";
import MicrophoneComponent from "../components/MicrophoneView";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { FaStop } from "react-icons/fa";
import { BsFillPlayFill } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SimpleSentenceVr = ({
  data,
  contentIndex,
  callbackExercisesRecordingAttempts,
}) => {
  const [isPlayingOne, setIsPlayingOne] = useState(false);
  const [isPlayingTwo, setIsPlayingTwo] = useState(false);
  const [recordingAttempts, setRecordingAttempts] = useState(0);
  const [responseDataScore, setResponseDataScore] = useState(null);
  const [valueContentIndex, setValueContentIndex] = useState(0);
  const audioRefOne = useRef();
  const audioRefTwo = useRef();

  const urlBucketAudio = `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/audios`;

  const getColorClass = (qualityScore) => {
    if (qualityScore <= 59) {
      return "red-word";
    } else if (qualityScore <= 79) {
      return "orange-word";
    } else {
      return "green-word";
    }
  };


  const getScoreMessage = (overallScore) => {
    if (overallScore <= 59) {
      return "Your pronunciation is off";
    } else if (overallScore <= 79) {
      return "You could use some practice";
    } else {
      return "You got it!";
    }
  };

  const handleAudioPlayOne = () => {
    audioRefOne.current.play();
    setIsPlayingOne(true);
  };

  const handleAudioPauseOne = () => {
    audioRefOne.current.pause();
    setIsPlayingOne(false);
  };

  const handleAudioEndedOne = () => {
    setIsPlayingOne(false);
  };

  const handleAudioPlayTow = () => {
    audioRefTwo.current.play();
    setIsPlayingTwo(true);
  };

  const handleAudioPauseTwo = () => {
    audioRefTwo.current.pause();
    setIsPlayingTwo(false);
  };

  const handleAudioEndedTwo = () => {
    setIsPlayingTwo(false);
  };

  const showContent = (type) => {
    if (data && data.type) {
      switch (type) {
        case "instruction":
          return (
            <div>
              <h3
                onChange={() => console.log("h3 change")}
                className="instruction-title"
              >
                Instruction
              </h3>
              <h5 className="instruction-text">{data.instruction}</h5>
            </div>
          );

        default:
          return (
            <>
              <div className="wrapper-ssvr">
                <h2>
                  <div
                    className="text-instructions"
                  >
                    {data.pt.length > 0 && data.pt !== "#" ? data.pt : data.en}
                  </div>
                </h2>

                <div className="wrapper-microphone-vr">
                  <div className="audio-attempts">
                    <span>
                      <span>
                        {contentIndex === valueContentIndex && (
                          <>{recordingAttempts}/3 tentativas</>
                        )}
                      </span>
                    </span>
                  </div>

                  <div
                    className="audio-container"
                    onClick={() => {
                      if (isPlayingOne) {
                        handleAudioPauseOne();
                      } else {
                        handleAudioPlayOne();
                      }
                    }}
                  >
                    <div className="audio-icon">
                      <FontAwesomeIcon
                        className="icon-step"
                        icon={faVolumeUp}
                      />
                      <audio
                        ref={audioRefOne}
                        src={`${urlBucketAudio}/${data.sound}`}
                        onEnded={handleAudioEndedOne}
                      />
                    </div>
                    <span>1. Listen to the audio</span>
                  </div>

                  {data.type === "drill" && (
                    <MicrophoneComponent
                      data={data}
                      contentIndex={contentIndex}
                      callbackResponseScore={setResponseDataScore}
                      callbackExercisesRecordingAttempts={
                        callbackExercisesRecordingAttempts
                      }
                      callbackAudioVrRecordingAttempts={setRecordingAttempts}
                      callbackAudioVrSetValueContentIndex={setValueContentIndex}
                      callbackAudioVrSetRecordingAttempts={setRecordingAttempts}
                    />
                  )}
                </div>
              </div>

              {contentIndex === valueContentIndex &&
                responseDataScore &&
                data.en === responseDataScore.data.text && (
                  <div className="overall-score-ssvr">
                    <div className="wrapper">
                      <div>
                        <button
                          className={isPlayingTwo ? "stop" : "start"}
                          onClick={() => {
                            if (isPlayingTwo) {
                              handleAudioPauseTwo();
                            } else {
                              handleAudioPlayTow();
                            }
                          }}
                        >
                          {isPlayingTwo ? <FaStop /> : <BsFillPlayFill />}
                        </button>
                        <audio
                          ref={audioRefTwo}
                          src={`${urlBucketAudio}/${data.sound}`}
                          onEnded={handleAudioEndedTwo}
                        />
                      </div>
                      <div className="word-paragraph-ssvr">
                      {responseDataScore.data.result.detailed.words.map(
                        (word, index) => (
                          <span
                            key={index}
                            className={getColorClass(word.quality_score)}
                          >
                            {word.word}{" "}
                          </span>
                        )
                      )}
                      </div>
                    </div>

                    <div className="overall-score-info-ssvr">
                      <span className="percent">
                        {responseDataScore.data.result.overall_score}%
                      </span>
                      <span>
                        {getScoreMessage(
                          responseDataScore.data.result.overall_score
                        )}
                      </span>
                    </div>
                  </div>
                )}
            </>
          );
      }
    }
  };

  return (
    <div className="default-card-ssvr">
      {data && data.type ? (
        showContent(data.type)
      ) : (
        <div className="default-card-ssvr">Template não configurado</div>
      )}
    </div>
  );
};

export default SimpleSentenceVr;
