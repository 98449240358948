import React, { Component } from "react";
import "../assets/templates/Audio.css";
import listenTalk from "../assets/images/listenTalk.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";

class Audio extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  hasModel() {
    const { blockData } = this.props;
    let instructionCount = 0;

    for (let i = 0; i < blockData.content.length; i++) {
      if (blockData.content[i].type === "instruction") instructionCount += 1;
    }

    if (instructionCount >= 2) {
      return true;
    } else {
      return false;
    }
  }
  render() {
    const { data, blockData, contentIndex } = this.props;
    return (
      <div className="default-card">
        <div style={{ width: "100%", minWidth: '800px' }}>
          {data.type === "instruction" ? (
            <div>
              <h3
                onChange={() => console.log("h3 change")}
                className="instruction-title"
              >
                Instruction
              </h3>
              <h5 className="instruction-text">{data.instruction}</h5>
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <div>
                  <img src={listenTalk} alt="ListenTalk" />
                </div>
                <div
                  className="audio-container"
                  onClick={() => {
                    this.props.resetPlayer();
                  }}
                >
                  <div className="audio-icon">
                    <FontAwesomeIcon className="icon-step" icon={faVolumeUp} />
                  </div>
                </div>
              </div>
              <br />
              {this.hasModel() &&
                blockData.complement &&
                blockData.complement.type !== "practice" && (
                  <div className="fluency-model">
                    <div className="fluency-model-title">Model</div>
                    <div className="fluency-model-text">
                      {blockData.content[1].en}
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Audio;
