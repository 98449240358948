import React, { Component } from "react";


class ChooseCorrectAnswer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      borderColorBool: false,
      data: [],
      buttonMsg: "Check"
    };
  }

  componentDidMount() {
    this.getwords();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.blockIndex !== this.props.blockIndex){
      this.getwords();
    }
  }

  componentWillReceiveProps(){
    this.getwords();
  }

  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  getwords() {
    let dataYes = this.props.blockData.complement.yes;
    let dataNo = this.props.blockData.complement.no;
    let arrYes = [];
    let arrNo = [];
    let concatObj = [];

    arrYes.push({
      value: dataYes,
      status: "yes",
      originalColor: "#9CD99F",
      selectedColor: "black",
      clicked: false
    });

    if(typeof dataNo === 'object'){
      arrNo = dataNo.map((value)=>{
        return {
          value: value,
          status: "no",
          originalColor: "red",
          selectedColor: "black",
          clicked: false
        };
      })
    } else {
      arrNo.push({
        value: dataNo,
        status: "no",
        originalColor: "red",
        selectedColor: "black",
        clicked: false
      });
    }

    concatObj = arrNo.concat(arrYes);

    this.setState({ data: this.shuffle(concatObj) });
  }

  changeColor(item) {
    let newData = this.state.data;
    newData.forEach(function(value) {
      if (item === value.value) {
        value.clicked = true;
      }
    });

    this.setState({data: newData });
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card container">
        {blockData.content[contentIndex].type === "instruction" ? (
          <div>
            <h3
              onChange={() => console.log("h3 change")}
              className="instruction-title"
            >
              Instruction
            </h3>
            <h5 className="instruction-text">
              {blockData.content[contentIndex].instruction}
            </h5>
          </div>
        ) : (
          <div>
            <div className="row">
              {this.state.data.map(item => (
                <div className="col-md-6">
                  <button
                    className="btn btn-default randButton"
                    onClick={() => {
                      this.changeColor(item.value);
                    }}
                    style={{
                      border: "solid 3px",
                      borderColor: item.clicked
                        ? item.originalColor
                        : "#29648D",
                      width: "400px",
                      height: "300px",
                      backgroundColor: "#29648D",
                      color: "white",
                      marginBottom: "40px",
                    }}
                  >{`${item.value.toUpperCase()}`}</button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ChooseCorrectAnswer;
