import React from 'react'
import { DropTarget } from 'react-dnd'
const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  marginRight: '2rem',
  marginBottom: '5rem',
  color: 'black',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
}
export const Dustbin = ({
  accepts,
  getPicture,
  name,
  isOver,
  canDrop,
  connectDropTarget,
  lastDroppedItem,
}) => {
  const isActive = isOver && canDrop
  let backgroundColor = 'transparent'
  if (isActive) {
    // backgroundColor = 'black'
  }else if(lastDroppedItem) {
    backgroundColor = 'transparent'
   }
   else if (canDrop) {
    // backgroundColor = 'darkkhaki'
  }

//   console.log('PROPS D', props)
  return connectDropTarget(
    <div style={{ ...style, backgroundColor }}>
        <div className='content'>
        </div>
    </div>
  )
}
export default DropTarget(
  props => props.accepts,
  {
    drop(props, monitor) {
      props.onDrop(monitor.getItem())
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(Dustbin)