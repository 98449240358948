import React, { Component } from "react";
import "../assets/templates/SingleCard.css";
import { getLanguagePack } from '../service/service.js'

class SingleCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flipped: false,
      clicked: false,
      languagePackage: getLanguagePack()
    };
  }

  getCurrentLanguage(){
    let data = localStorage.getItem('languagePack')
    return data == null ? 'en' : JSON.parse(data).selectedLanguage
  }
  _getMessage(message){
    return this.state.languagePackage[message] ? this.state.languagePackage[message] : message
  }

  componentDidUpdate(res, statev) {
    if (statev.flipped) {
      this.setState({ flipped: false });
    }
  }
  render() {
    const { data } = this.props;

    return (
      <div className="default-card" style={{ textAlign: "center" }}>
        {data.type === "instruction" ? (
          <div>
            <h3
              onChange={() => console.log("h3 change")}
              className="instruction-title"
            >
              Instruction
            </h3>
            <h5 className="instruction-text">{data.instruction}</h5>
          </div>
        ) : (
          <div>
            <h4 style={{ marginBottom: "1.5rem" }}>
              {this._getMessage('Tap the image to see the sentence.')}
            </h4>
            <div
              className={`Card ${!this.state.flipped ? "Card-animation" : ""}`}
            >
              <div
                style={{
                  display: !this.state.flipped ? "block" : "none",
                  alignItems: "center"
                }}
                className={"Card-Front"}
                ref={"card"}
                onClick={() => {
                  this.setState({ flipped: true });
                }}
                id={"front"}
              >
                <div className="Card-itens-front">
                  <img
                    alt="Mosaic"
                    className="mosaic-img"
                    src={`${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/${
                      data.picture
                    }`}
                  />
                </div>
              </div>
              <div
                style={{ display: this.state.flipped ? "block" : "none" }}
                className={
                  "Card-Back Flip " +
                  (this.state.flipped ? "Card-Back-Flip" : "Card-Front-Flip")
                }
                ref={"card"}
                onClick={() => {
                  this.setState({ flipped: false });
                }}
                id={"back"}
              >
                <div className="Card-itens-back">
                  <h4>{data.en === "" ? data.pt : data.en}</h4>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SingleCard;
