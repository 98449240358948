import React from 'react'
import { DragSource } from 'react-dnd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'

export const Box = ({ name, isDropped, isDragging, connectDragSource, orderBy}) => {
  const opacity = isDragging ? 0.4 : 1
  const backColor = isDropped ? 'transparent':  'white' 
  return connectDragSource(
    <div className="dialog-grab" style={{ opacity, backgroundColor: backColor, order: orderBy}}>
        {
            !isDropped &&
            <div className="dialog-grab-icon">
                <FontAwesomeIcon className="icon-role" icon={ faVolumeUp } /> 
            </div>
        }
        {isDropped ? '': name}
    </div>
  )
}
export default DragSource(
  props => props.type,
  {
    beginDrag: props => ({ name: props.name }),
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(Box)
