import React, { Component } from "react";
import '../assets/templates/TextIllustrated.css';

class TextIllustrated extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  getImageUrl(name){
      return `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/` + name
  }


  getHilighted(text){
    let splitText = text.split(/( )/)
    let textObj = []
    for(let i = 0; i < splitText.length; i++){
        if(splitText[i].includes("[") || splitText[i].includes("]") ){
            textObj.push({word: splitText[i].replace("[","").replace("]",""), highLight: true})
        }else{
            textObj.push({word: splitText[i], highLight: false})
        }
    }
    return textObj
  }

  render() {
    const { data, contentIndex } = this.props;
    return (
      <div className="default-card">
        {
            data.type === 'instruction' ?
            <div>
                <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                <h5 className="instruction-text">{ data.instruction }</h5>
            </div>
            :
            <div className="illustrated-container">   
                <img className="illustrated-img" src={this.getImageUrl(data.img)} />
                <div className="illustrated-words-container">
                    {
                        data.sentences.map((item, index) => {
                            return(
                                <div className="illustrated-word" key={index}>
                                 <span> { item.character }: </span>   { this.getHilighted(item.text).map((words, wordIndex) => {
                                     return(
                                        <span key={wordIndex} style={{
                                            color: words.highLight ? 'rgb(47, 157, 201)' : "black",
                                            fontWeight: words.highLight ? 'bold' : 'normal'}}>
                                            { words.word } 
                                        </span>
                                     )
                                 }) }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            
        }
      </div>
    )
  }
}

export default TextIllustrated;
