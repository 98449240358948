import React, { useState, useCallback } from "react";
import Dustbin from "../components/DustbinSong";
import Box from "../components/BoxSong";
import update from "immutability-helper";
import SongPicture from '../assets/images/partitura-adult.png'

const Container = props => {
  const { blockData, contentIndex, datas } = props;

  const { title } = blockData.complement.song;

  let start = 0;

  function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  const [dustbins, setDustbins] = useState(getHilighted(datas));

  const [droppedBoxNames, setDroppedBoxNames] = useState([]);
  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }
  const handleDrop = useCallback(
    (index, item) => {
      const { name } = item;
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] })
      );
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item
            }
          }
        })
      );
    },
    [droppedBoxNames, dustbins]
  );
  let array = [];
  let countData = getHilighted(datas).length;

  function splitText(getdata, index) {
    let removeParentheses = getdata.match(/\[(.*?)\]/g);

    array.push(removeParentheses[index]);

    start =
      index === 0
        ? start
        : parseInt(
            getdata.indexOf(array[index - 1]) + array[index - 1].length + 1
          );

    let lastString = getdata.indexOf(array[index]);
    let result = getdata.slice(start, lastString);

    return result;
  }

  function lastText(getdata) {
    let lastText = "";

    if (array[countData - 1] !== undefined) {
      lastText = getdata.slice(
        getdata.lastIndexOf(array[countData - 1]) + array[countData - 1].length,
        getdata.length
      );
    }
    return lastText;
  }

  function getHilighted(text) {
    let splitText = text.match(/\[(.*?)\]/g);

    let textObj = [];
    for (let i = 0; i < splitText.length; i++) {
      if (splitText[i].includes("[") || splitText[i].includes("]")) {
        textObj.push({
          word: splitText[i].replace("[", "").replace("]", ""),
          lastDroppedItem: null
        });
      }
    }

    return textObj;
  }

  return (
    <div className="default-card container">
      {blockData.content[contentIndex].type === "instruction" ? (
        <div>
          <h3
            onChange={() => console.log("h3 change")}
            className="instruction-title"
          >
            Instruction
          </h3>
          <h5 className="instruction-text">
            {blockData.content[contentIndex].instruction}
          </h5>
        </div>
      ) : (
        <div>
          <div className="row">
            <div className="col-md-4" />
            <div className="col-md-8">
              <h4>{title}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12">
                  <img src={SongPicture} alt="SongPicture"/>
                  {/* <img src="../assets/images/partitura-adult.png" /> */}
                  <br />
                </div>
                <div className="col-md-12">
                  <br />
                  {shuffle(getHilighted(datas)).map(function(
                    { word, lastDroppedItem },
                    index
                  ) {
                    return (
                      <Box
                        name={word}
                        type={word}
                        isDropped={isDropped(word)}
                        key={index}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4" />
                <div className="col-md-8">
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "50px",
                  float: "left",
                  display: "inline"
                }}
              >
                {dustbins.map(function({ word, lastDroppedItem }, index) {
                  return (
                    <Dustbin
                      accepts={word.replace("[", "")}
                      lastDroppedItem={lastDroppedItem}
                      name={word.replace("[", "")}
                      text={`${splitText(datas, index)}`}
                      onDrop={item => handleDrop(index, item)}
                      key={index}
                    />
                  );
                })}
                <span
                  style={{
                    color: "black",
                    lineHeight: "50px",
                    whiteSpace: "pre-line"
                  }}
                >
                  &nbsp;{lastText(datas).replace(/-/g, "\n")}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Container;
