import React from "react";
import { DropTarget } from "react-dnd";

const style = {
  //   border: 'black solid 1px',
  display: "contents",
  alignItems: "center",
  justifyContent: "left",

  color: "black",
  padding: "1rem",
  textAlign: "left",
  fontSize: "1rem",
  lineHeight: "50px",
  float: "left"
};
export const Dustbin = ({
  accepts,
  //   getPicture,
  name,
  text,
  lastText,
  isOver,
  canDrop,
  connectDropTarget,
  lastDroppedItem,
  key
}) => {
  const isActive = isOver && canDrop;
  let backgroundColor = "white";
  if (isActive) {
    // backgroundColor = 'black'
  } else if (lastDroppedItem) {
    // backgroundColor = 'black'
    // console.log("SUCESSO")
  } else if (canDrop) {
    // backgroundColor = 'darkkhaki'
  }

  function wrapTextNode(textNode) {
    var spanNode = document.createElement("input");

    spanNode.setAttribute("class", "inputHeigth");
    spanNode.setAttribute("readonly", "readonly");
    var newTextNode = document.createTextNode(textNode.textContent);
    spanNode.appendChild(newTextNode);
    textNode.parentNode.replaceChild(spanNode, textNode);
  }

  [].forEach.call(document.querySelectorAll("#input"), function(el) {
    var textNode = el.childNodes[0];

    wrapTextNode(textNode);
  });

  return connectDropTarget(
    <span style={{ ...style, backgroundColor, whiteSpace: "pre-line" }}>
      &nbsp;{text.replace(/ - /g, "\n")}&nbsp;
      {lastDroppedItem ? <i style={{ color: 'green', fontWeight: 'bold' }}>{accepts}</i> : <span id="input"> </span>}
    </span>
  );
};
export default DropTarget(
  props => props.accepts,
  {
    drop(props, monitor) {
      props.onDrop(monitor.getItem());
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(Dustbin);
