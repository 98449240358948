import React, { Component } from "react";
import '../assets/templates/TextPagesHL.css';

class TextPagesHL extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  getHilighted(text) {
    let splitText = text.split(/( )/)
    let textObj = []
    for (let i = 0; i < splitText.length; i++) {
      if (splitText[i].includes("[") || splitText[i].includes("]")) {
        textObj.push({ word: splitText[i].replace("[", "").replace("]", ""), highLight: true })
      } else {
        textObj.push({ word: splitText[i], highLight: false })
      }
    }
    return textObj
  }


  render() {
    const { data } = this.props;
    return (
      <div className="default-card">
        {
          data.type === 'instruction'
            ?
            <div>
              <h3 onChange={() => console.log('h3 change')} className="instruction-title">Instruction</h3>
              <h5 className="instruction-text">{data.instruction}</h5>
            </div>
            :

            <div>
              {

                data.en.split("\r\n").map((item, index) => {
                  return (
                    <div key={index} className="text-pages-container">
                    {
                      this.getHilighted(item).map((w)=>{
                        return(
                            <h2 style={{color: w.highLight ? 'rgb(47, 157, 201)':'white'}}>
                               { w.word }
                            </h2>
                        )
                    })
                      }
                     </div>
                      
                  );
                })
              }
            </div>

        }
      </div>
    )
  }
}

export default TextPagesHL;
