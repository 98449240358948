import React, { Component } from "react";
import '../assets/templates/TextPagesIllustrated.css';

class TextPagesIllustrated extends Component {
  constructor(props) {
    super(props)

    this.state = {
        language: 'en'
    }
  }

  getImageUrl(name){
      return `${process.env.REACT_APP_CLOUDFRONT_DNS}/media/images/` + name
  }


  getHilighted(text){
    let splitText = text.split(/( )/)
    let textObj = []
    for(let i = 0; i < splitText.length; i++){
        if(splitText[i].includes("[") || splitText[i].includes("]") ){
            textObj.push({word: splitText[i].replace("[","").replace("]",""), highLight: true})
        }else{
            textObj.push({word: splitText[i], highLight: false})
        }
    }
    return textObj
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card">
        {
            blockData.content[contentIndex].type === 'instruction' ?
            <div>
                <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                <h5 className="instruction-text">{ blockData.content[contentIndex].instruction }</h5>
            </div>
            :
            <div className="illustrated-container">
                <img className="illustrated-img" alt="TextIllustrated" src={this.getImageUrl(blockData.complement.img.img)} />
                <div className="illustrated-pages-container">
                    <div className="change-language-container" >
                        <div 
                            className="language-select" 
                            style={{ backgroundColor: this.state.language === 'en' ? 'red' : 'rgb(65,182,230)' }}
                            onClick={() => this.setState({ language: 'en' })}>
                            EN
                        </div>
                        <div 
                            className="language-select" 
                            style={{ backgroundColor: this.state.language === 'pt' ? 'red' : 'rgb(65,182,230)' }}
                            onClick={() => this.setState({ language: 'pt' })}>
                            PT
                        </div>
                    </div> 
                    <div style={{fontSize: 32, color: 'rgb(97,97,97)'}}>
                        {
                            this.state.language === 'en'
                                ? blockData.content[contentIndex].titleEn
                                : blockData.content[contentIndex].titlePt
                        }
                    </div>
                    <div>
                        {
                            this.state.language === 'en'
                                ? blockData.content[contentIndex].en
                                : blockData.content[contentIndex].pt
                        }
                    </div>
                </div>
            </div>
            
        }
      </div>
    )
  }
}

export default TextPagesIllustrated;
