import React, { useState, useCallback } from "react";
import Dustbin from "../components/DustBinText";
import Box from "../components/BoxText";
import update from "immutability-helper";

const TextDialogDnD = props => {
  const { blockData, datas, shuffledData } = props;
  const { titulo } = blockData.complement;
  let [solved, setSolved] = useState(false)

  let start = 0;

  const [dustbins, setDustbins] = useState(getHilighted(datas));

  const [droppedBoxNames, setDroppedBoxNames] = useState([]);
  function isDropped(boxName) {
    return droppedBoxNames.indexOf(boxName) > -1;
  }
  const handleDrop = useCallback(
    (index, item) => {
      const { name } = item;
      setDroppedBoxNames(
        update(droppedBoxNames, name ? { $push: [name] } : { $push: [] })
      );
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item
            }
          }
        })
      );
    },
    [droppedBoxNames, dustbins]
  );
  let array = [];
  let countData = getHilighted(datas).length;

  function solve(){
    let allData = []
    let allDustBins = dustbins
    
    for(let i = 0; i < dustbins.length; i++){
      allData.push(dustbins[i].word)
      allDustBins[i].lastDroppedItem = {name: dustbins[i].word}
    }

    setDroppedBoxNames(
      update(droppedBoxNames, { $push: allData } )
    )
    setDustbins(
      update(dustbins, { $set: allDustBins})
    );

  }

  function tryAgain(){
    let allDustBins = dustbins
    
    for(let i = 0; i < dustbins.length; i++){
      allDustBins[i].lastDroppedItem = undefined
    }
    setDroppedBoxNames(
      update(droppedBoxNames, { $set: [] } )
    )
    setTimeout(() => {
      setDroppedBoxNames(
        update(droppedBoxNames, { $set: [] } )
      )
    }, 100);

    setDustbins(
      update(dustbins, { $set: allDustBins})
    );

    setSolved(false)

  }

  function splitText(getdata, index) {
    let removeParentheses = getdata.match(/\[(.*?)\]/g);

    array.push(removeParentheses[index]);

    start =
      index === 0
        ? start
        : parseInt(
            getdata.indexOf(array[index - 1]) + array[index - 1].length + 1
          );

    let lastString = getdata.indexOf(array[index]);
    let result = getdata.slice(start, lastString);

    return result;
  }

  function lastText(getdata) {
    let lastText = "";

    if (array[countData - 1] !== undefined) {
      lastText = getdata.slice(
        getdata.lastIndexOf(array[countData - 1]) + array[countData - 1].length,
        getdata.length
      );
    }
    return lastText;
  }

  function getHilighted(text) {
    let splitText = text.match(/\[(.*?)\]/g);

    let textObj = [];
    for (let i = 0; i < splitText.length; i++) {
      if (splitText[i].includes("[") || splitText[i].includes("]")) {
        textObj.push({
          word: splitText[i].replace("[", "").replace("]", ""),
          lastDroppedItem: null
        });
      }
    }

    return textObj;
  }

  return (
    <div className="default-card container" style={{ maxWidth: 'initial' }}>
        <div>
          <div className="row">
            <div className="col-md-4" />
            <div className="col-md-8">
              <h4>{titulo}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4" />
                <div className="col-md-8">
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "50px",
                  float: "left",
                  display: "inline"
                }}
              >
                {dustbins.map(function({ word, lastDroppedItem }, index) {
                  return (
                    <Dustbin
                      accepts={word.replace("[", "")}
                      lastDroppedItem={lastDroppedItem}
                      name={word.replace("[", "")}
                      text={`${splitText(datas, index)}`}
                      onDrop={item => handleDrop(index, item)}
                      key={index}
                    />
                  );
                })}
                <span
                  style={{
                    color: "black",
                    lineHeight: "50px",
                    whiteSpace: "pre-line"
                  }}
                >
                  &nbsp;{lastText(datas).replace(/ - /g, "\n")}
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-12">
                  <br />
                  {shuffledData.map(function(
                    { word, lastDroppedItem },
                    index
                  ) {
                    return (
                      <Box
                        name={word}
                        type={word}
                        isDropped={isDropped(word)}
                        key={index}
                      />
                    );
                  })}
                </div>
              </div>
            </div>

            <div onClick={() => { 
              if(solved){
                props.step('forward')
              }else{
                setSolved(true)
                solve()
              }
               }} className="listen-drag-check-maze">
                {
                  solved
                    ? 'Done'
                    : 'Check'
                }
                
            </div>
                {
                  solved &&
                    <div onClick={() => { 
                      tryAgain()
                      }} className="listen-drag-check-maze" style={{ right: 150 }}>
                      Try Again
                   </div>
                }
          </div>
        </div>
      
    </div>
  );
};
export default TextDialogDnD;
