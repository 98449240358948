import React, { Component } from "react";
import '../assets/templates/IrregularVerbs.css';

class IrregularVerbs3 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      index: 0,
      divPositions: []
    }
  }

  componentDidUpdate(prevProps, prevState){
    if(prevProps.contentIndex !== this.props.contentIndex){
      this.setState({
        open: false,
      })
    }
  }

  _reset(){
    this.setState({
      open: false
    })
  }

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card">
        <div style={{width: '90%'}}>
            {
                blockData.content[contentIndex].type === 'instruction' ?
                <div>
                    <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                    <h5 className="instruction-text">{ blockData.content[contentIndex].instruction }</h5>
                </div>
                :
                <div style={{width: '100%'}}>   
                    {
                        blockData.content.filter((x) => { return x.type !== "instruction"}).map((item, index)=>{
                            return(
                                <div 
                                ref={(ref)=>{
                                    // scroll to the current div position
                                    if(ref && item.id === blockData.content[contentIndex].id){ window.scrollTo(0, ref.offsetTop - (window.innerHeight / 2)) }
                                }}
                                key={index} className={"irregular-verbs-container " + (item.id === blockData.content[contentIndex].id ? "simple-active" : "")} >
                                    {
                                        item.en.split(',').map((verb, verbIndex) => {
                                            return(
                                                <h2 key={verbIndex} style={{color: "white"}}>
                                                    { verb } 
                                                </h2> 
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                
            }
        </div>
      </div>
    )
  }
}

export default IrregularVerbs3
