import React, { useState, useCallback, useEffect } from "react";
import Dustbin from "../components/DustbinTextPagesScrambled";
import Box from "../components/BoxTextPagesScrambled";
import update from "immutability-helper";

const Container = props => {
  const { blockData, contentIndex } = props;
  const data = blockData.content[contentIndex].en === "" ? blockData.content[contentIndex].pt : blockData.content[contentIndex].en;

  const [dustbins, setDustbins] = useState([]);
  const [boxOptions, setBoxOptions] = useState([]);

  function shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  const handleDrop = useCallback(
    (index, item) => {
      const { name } = item;
      const removeIndex = boxOptions.findLastIndex((value)=>value.word === name);
      const currentBoxOptions = [...boxOptions];
      currentBoxOptions.splice(removeIndex, 1)
      setBoxOptions([...currentBoxOptions]);
      setDustbins(
        update(dustbins, {
          [index]: {
            lastDroppedItem: {
              $set: item
            }
          }
        })
      );
    },
    [dustbins, boxOptions]
  );

  function getSentences() {
    const splitData = data.split(" ");
    const sentenceData = splitData.map((value, index) => {
      if(value[0]==='['){
        return {
          value,
          isLacuna: true
        };
      }
      return {
        value,
        isLacuna: false
      };
    });
    const sentences = [''];
    let countLacuna = 0;
    sentenceData.forEach((value, sentenceIndex) => {
      if(!value.isLacuna && sentences.length > 0 && sentences[countLacuna][0] !== '['){
        sentences[countLacuna] = `${sentences[countLacuna]} ${value.value}`;
      }else{
        sentences.push(value.value);
        countLacuna+=1
      }
      // Será adicionado um item vazio ao array quando tiver lacunas em sequencia
      if(value.isLacuna && sentenceData[sentenceIndex+1].isLacuna){
        sentences.push('');
        countLacuna+=1
      }
    })
    const filteredSentences = sentences.filter((value, index)=> value[0] !== '[');
    return filteredSentences;
  }

  function splitText(index) {
    const filteredSentences = getSentences();

    if(index > filteredSentences.length - 1){
      return '';
    }
    
    return filteredSentences[index];
  }

  function lastText() {
    const lastText = getSentences();
    return lastText[lastText.length - 1];
  }

  function getHilighted(text) {
    let splitText = text.match(/\[(.*?)\]/g);
    let textObj = [];
    for (let i = 0; i < splitText.length; i++) {
      if (splitText[i].includes("[") || splitText[i].includes("]")) {
        textObj.push({
          word: splitText[i].replace("[lacuna:", "").replace("]", ""),
          lastDroppedItem: null
        });
      }
    }
    return textObj;
  }

  const fetch = useCallback(() => {
    const dataContent = blockData.content[contentIndex].en === "" ? blockData.content[contentIndex].pt : blockData.content[contentIndex].en;
    if(dataContent){
      const shuffledOptions = shuffle(getHilighted(dataContent));
      setBoxOptions(shuffledOptions);
      const dustbinsData = getHilighted(blockData.content.filter(a => !a.instruction)[0].en === "" ? blockData.content.filter(a => !a.instruction)[0].pt : blockData.content.filter(a => !a.instruction)[0].en);
      setDustbins(dustbinsData);
    }
  },[blockData.content, contentIndex])

  useEffect(() => {
    if(blockData.content[contentIndex].type !== 'instruction'){
      fetch();
    }
  },[blockData, contentIndex, fetch])

  return (
    <div className="default-card container scroll-disable">
      {blockData.content[contentIndex].type === "instruction" ? (
        <div>
          <h3
            onChange={() => console.log("h3 change")}
            className="instruction-title"
          >
            Instruction
          </h3>
          <h5 className="instruction-text">
            {blockData.content[contentIndex].instruction}
          </h5>
        </div>
      ) : (

        <div>
          {boxOptions.length < 1 && (
            <div
              className="listen-drag-check-maze"
              onClick={() => {
                props.step('forward', true)
              }}>
              {'Concluir'}
            </div>
          )}
          <div className="row">
            <div className="col-md-4">
            </div>
            <div className="col-md-8">
              <h4>{blockData.content[contentIndex].en === "" ? blockData.complement.titlePt : blockData.complement.titleEn}</h4>
            </div>
          </div>
          <div className="row">



            <div className="col-md-4 listBoxTextPage" >

              {boxOptions.map(function (
                { word, lastDroppedItem },
                index
              ) {
                return (
                  <Box
                    name={word}
                    type={word}
                    key={`${word}${index}`}
                  />
                );
              })}
            </div>

            <div
              className="col-md-7"
              style={{
                backgroundColor: "#fff",
                padding: "25px",
                float: "left",
                display: "inline",
                overflowY: "auto",
                height: "55vh"
              }}
            >


              {dustbins.map(function ({ word, lastDroppedItem }, index) {
                return (
                  <Dustbin
                    accepts={word}
                    lastDroppedItem={lastDroppedItem}
                    name={word}
                    text={`${splitText(index)}`}
                    onDrop={item => handleDrop(index, item)}
                    key={`${word}${index}`}
                  />
                );
              })}
              <span style={{ color: "black", lineHeight: '50px', whiteSpace: 'pre-line' }}>&nbsp;{lastText()}</span>
            </div>

          </div>
        </div>
      )}
    </div>

  );

};
export default Container;
