import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { loginManager, setToken, getTranslation, getUser } from "../service/service.js"
import qs from 'qs'
import cookie from 'react-cookies'
import sendDataLayerEvent from "../components/sendDataLayerEvent.js";



class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: '',
      password: '',
      loggedIn: false,
      isLoading: false,
      error: false,
      errorMessage: '',
    }
  }

  setLanguage(user, region) {
    let language_code
    let language = []

    if (region !== undefined && user.data.available_languages.some(x => x.code === region)) {
      language_code = region
    } else {
      if (user.data.region_user && user.data.region_user !== 'BRA' && user.data.available_languages[0].code === 'en') {
        language_code = user.data.region_code
      } else {
        language_code = user.data.available_languages[0].code
      }
    }
    getTranslation(language_code).then(res => {
      language = res.data
      language.selectedLanguage = user.data.available_languages[0].code
      language.languageCode = language_code

      localStorage.setItem('languagePack', JSON.stringify(language))
      window.location.reload()
    }).catch(err => {
      console.log('getTranslation error: ', err)
    }).finally(() => {
      this.setState({ loggedIn: true })
    })

    return language_code
  }

  resetCookie() {
    cookie.remove('token_info', { path: '/' })
  }

  _login() {
    localStorage.removeItem('languagePack')
    this.setState({
      error: false
    })
    let data = {
      username: this.state.user,
      password: this.state.password,
      grant_type: 'password',
    }
    this.setState({ isLoading: true })
    loginManager(data).then(res => {
      console.log('res: ', res)
      const user = res.user
      if (user.data.available_languages.length > 1) {
        this.setState({ loggedIn: true })
      }
      else{
        this.setState({ loggedIn: true })
        this.setLanguage(user)
      }

      sendDataLayerEvent('login')

    }).catch(err => {
      this.setState({
        isLoading: false,
        error: true,
        errorMessage: err.message
      })
      console.log('failed to log: ', err)
    })
  }

  getRedirectToken() {
    let params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

    let tokenData = {}

    if (Object.keys(params).length > 0) { // if has params
      tokenData.token_type = "Bearer"
      tokenData.access_token = params.token

      setToken(tokenData).then(res => {
        console.log("set token success")

        this.setState({ loggedIn: true })
        this.setState({ isLoading: false })
        this.setState({ error: false })

        getUser().then(user => {
          localStorage.setItem('user-info', JSON.stringify(user))
          this.setLanguage(user, params.region)
          this.setState({ isLoading: true })
        }).catch(err => {
          this.setState({
            isLoading: false,
            error: true,
            errorMessage: err.message
          })
          console.log('failed to log: ', err)
        })
      }).catch(err => {
        console.log("set token ERROR")
      })
    }
  }

  componentDidMount() {
    this.getRedirectToken()
  }

  getToken() {
    console.log('userId cookie: ', cookie.load('userId'))
  }

  render() {
    if (this.state.loggedIn) {
      return (
        <Redirect to={{ pathname: '/classes/list' }} />
      )
    }
    return (
      <div className=" anonymous  login ">
        <div
          className="container-fluid background-image d-flex justify-content-center align-items-center"
          id="wizme_app"
        >
          <div className="container">
            <div className="card card-login">
              <div className="card-body">
                <div className="bloco-login">
                  <div >
                    <input
                      type="hidden"
                      name="csrfmiddlewaretoken"
                      defaultValue="8r2y52LS1OLf3hXonxQ2opIZyS2WziDK"
                    />
                    <div className="bloco-login__logo">
                      <h1 className="card-logo">Wizard</h1>
                    </div>

                    <div className="card-text">
                      <section>
                        <div className="form-group form-login">
                          <label
                            htmlFor="username"
                            className="label-login__language label-login__language-darker"
                          >
                            Username
                          </label>
                          <input
                            className="form-control input-wiz"
                            type="text"
                            id="id_username"
                            name="username"
                            defaultValue=""
                            placeholder="username"
                            data-toggle="tooltip"
                            onChange={(e) => this.setState({ user: e.target.value })}
                          />
                        </div>

                        <div className="form-group form-login">
                          <label
                            htmlFor="password"
                            className="label-login__translate label-login__translate-darker"
                          >
                            Password
                          </label>
                          <input
                            onKeyDown={(e) => { if (e.key === 'Enter') this._login() }}
                            className="form-control input-wiz"
                            type="password"
                            id="password"
                            name="password"
                            defaultValue=""
                            placeholder="password"
                            onChange={(e) => this.setState({ password: e.target.value })}
                          />
                        </div>
                      </section>
                      <div>
                        <br />
                        <div
                          className="btn btn-primary btn-lg btn-block btn-login login-button"
                          onClick={() => { this._login() }}>
                          {
                            !this.state.isLoading
                              ? 'Enter'
                              : <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                          }
                        </div>

                        <div id="loading-login">
                          <div className="loading-more" />
                        </div>
                      </div>
                      {
                        this.state.error &&
                        <div className="login-error">
                          {this.state.errorMessage}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Login;
