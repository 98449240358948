import React, { Component } from "react";
import Collapsible from "react-collapsible";
import '../assets/templates/DoubleSentence.css';

class DoubleSentenceStatic extends Component {
  constructor(props) {
    super(props)

    this.state = {
      open: true,
      openSecond: false,
      index: 0
    }
  }

  render() {
    const { data, en } = this.props;
    return (
      <div className="default-card">
      <div>
      </div>
            {
              data.type === 'instruction'
                ?
                <div>
                  <h3 onChange={()=> console.log('h3 change')} className="instruction-title">Instruction</h3>
                  <h5 className="instruction-text">{ data.instruction }</h5>
                </div>
                :
                <div>
                    <h2>
                    <Collapsible 
                    triggerTagName="div" 
                    className="collapse-opened" 
                    trigger={en ? data.en : data.pt}
                    open={true}
                    handleTriggerClick={()=>this.setState({ open: !this.state.open })}
                    onClosing={()=>{this.setState({ isClosing: true })}}
                    onOpening={()=>{this.setState({ isClosing: false })}}
                    >
                    { en ? data.pt : data.en }
                    </Collapsible>
                  </h2>
                </div>
            }

          
      </div>
    )
  }
}

export default DoubleSentenceStatic;
