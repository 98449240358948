import React from 'react'
import { DropTarget } from 'react-dnd'
import "../assets/templates/TextPagesScrambled.css";


const style = {
//   border: 'black solid 1px',
  display: 'contents',
  alignItems: 'center',
  justifyContent: 'left',
 
  color: 'black',
  padding: '1rem',
  textAlign: 'left',
  fontSize: '1rem',
  lineHeight: '50px',
  whiteSpace: 'pre-line',
//   float: 'left',
  whiteSpace: 'pre-line',

}
export const Dustbin = ({
  accepts,
//   getPicture,
  name,
  text,
  lastText,
  isOver,
  canDrop,
  connectDropTarget,
  lastDroppedItem,
  key
}) => {
  const isActive = isOver && canDrop
  let backgroundColor = 'white'
  if (isActive) {
    // backgroundColor = 'black'
  }else if(lastDroppedItem) {
    // backgroundColor = 'black'
    // console.log("SUCESSO")
   }
   else if (canDrop) {
    // backgroundColor = 'darkkhaki'
  }

function setInput(text){
 
return text.replace(text.match(/\[(.*?)\]/g), '')
}

function wrapTextNode(textNode) {
    var spanNode = document.createElement('input');
    // spanNode.setAttribute('style', {'line-height': 'normal !important'});
    spanNode.setAttribute('class', 'inputHeigth');
    spanNode.setAttribute('readonly','readonly');
    var newTextNode = document.createTextNode(textNode.textContent);
    spanNode.appendChild(newTextNode);
    textNode.parentNode.replaceChild(spanNode, textNode);
    // console.log("spanNode", spanNode, "textNode", textNode)
}

[].forEach.call(document.querySelectorAll('#input'), function(el) {
    var textNode = el.childNodes[0];
    // console.log("textNode",el)
    wrapTextNode(textNode);
});

  
  return connectDropTarget(
    <span style={{ ...style, backgroundColor }}  id="myInput">
      &nbsp;{`${setInput(text)} `} &nbsp;
    

  
      {lastDroppedItem  ? ( <span>{accepts}</span> ) :  (<span id="input"> </span>)}
      
    </span>
  )
  
}
export default DropTarget(
  props => props.accepts,
  {
    
    drop(props, monitor) {
        // console.log('props', props, 'monitor', monitor)
    //    let elem =  document.getElementsByTagName("span")
    //     elem.parentNode.removeChild(elem)
      props.onDrop(monitor.getItem())
    },
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }),
)(Dustbin)