import React, { Component } from "react";
import "../assets/templates/Maze.css";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

// Coluna 1
const SortableItem1 = SortableElement(({ value1 }) => (

  <div
    className="illustrated-word"
    style={{
      backgroundColor:
        typeof value1.right1 == "undefined"
          ? "#062E57"
          : value1.right1
          ? "#69f0ae"
          : "#ff5252",
      textAlign: "center",
      color: "white",
      height: "auto",
      lineHeight: 2,
      minHeight: "80px",
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {value1.en.split("/")[0]}
  </div>
));

const SortableList1 = SortableContainer(({ items1 }) => {
  return (
    <ul>
      {items1.map((value1, index) => (
        <SortableItem1
          key={`item-${value1.id}`}
          index={index}
          value1={value1}
          disabled={true}
        />
      ))}
    </ul>
  );
});

// Coluna 2
const SortableItem2 = SortableElement(({ value2 }) => (
  <div
    className="illustrated-word"
    style={{
      backgroundColor:
        typeof value2.right2 == "undefined"
          ? "#062E57"
          : value2.right2
          ? "#69f0ae"
          : "#ff5252",
      textAlign: "center",
      color: "white",
      height: "auto",
      lineHeight: 2,
      minHeight: "80px",
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {value2.en.split("/")[1]}
  </div>
));

const SortableList2 = SortableContainer(({ items2 }) => {
  return (
    <ul>
      {items2.map((value2, index) => (
        <SortableItem2
          key={`item-${value2.id}`}
          index={index}
          value2={value2}
        />
      ))}
    </ul>
  );
});

// Coluna 3
const SortableItem3 = SortableElement(({ value3 }) => (
  <div
    className="illustrated-word"
    style={{
      backgroundColor:
        typeof value3.right3 == "undefined"
          ? "#062E57"
          : value3.right3
          ? "#69f0ae"
          : "#ff5252",
      textAlign: "center",
      color: "white",
      height: "auto",
      lineHeight: 2,
      minHeight: "80px",
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {value3.en.split("/")[2]}
  </div>
));

const SortableList3 = SortableContainer(({ items3 }) => {
  return (
    <ul>
      {items3.map((value3, index) => (
        <SortableItem3
          key={`item-${value3.id}`}
          index={index}
          value3={value3}
        />
      ))}
    </ul>
  );
});

// Coluna 4
const SortableItem4 = SortableElement(({ value4 }) => (
  <div
    className="illustrated-word"
    style={{
      backgroundColor:
        typeof value4.right4 == "undefined"
          ? "#062E57"
          : value4.right4
          ? "#69f0ae"
          : "#ff5252",
      textAlign: "center",
      color: "white",
      height: "auto",
      lineHeight: 2,
      minHeight: "80px",
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    {value4.en.split("/")[3]}
  </div>
));

const SortableList4 = SortableContainer(({ items4 }) => {
  return (
    <ul>
      {items4.map((value4, index) => (
        <SortableItem4
          key={`item-${value4.id}`}
          index={index}
          value4={value4}
        />
      ))}
    </ul>
  );
});

class Maze extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items1: [],
      items2: [],
      items3: [],
      items4: [],
      itemsRamdom1: [],
      itemsRamdom2: [],
      itemsRamdom3: [],
      itemsRamdom4: [],
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      buttonMsg: "Check",
      solved1: false,
      solved2: false,
      solved3: false,
      solved4: false
    };
  }

  _getitems1() {
    const { blockData } = this.props;
    let items = [];

    items = blockData.content
      .filter(a => a.type !== "instruction")
      .map((item, index1) => {
        item.order1 = index1;
        item.right1 = undefined
        return item;
      });

    this.setState({
      items1: items,
      itemsRamdom1: items,
      initialitems1: items
    });
  }

  _getitems2() {
    const { blockData } = this.props;
    let items = [];

    items = blockData.content
      .filter(a => a.type !== "instruction")
      .map((item, index2) => {
        item.order2 = index2;
        item.right2 = undefined
        return item;
      });

    this.setState({
      items2: items,
      itemsRamdom2: this._shuffle(items),
      initialitems2: items
    });
  }

  _getitems3() {
    const { blockData } = this.props;
    let items = [];

    items = blockData.content
      .filter(a => a.type !== "instruction")
      .map((item, index3) => {
        item.order3 = index3;
        item.right3 = undefined
        return item;
      });

    this.setState({
      items3: items,
      itemsRamdom3: this._shuffle(items),
      initialitems3: items
    });
  }

  _getitems4() {
    const { blockData } = this.props;
    let items = [];

    items = blockData.content
      .filter(a => a.type !== "instruction")
      .map((item, index4) => {
        item.order4 = index4;
        item.right4 = undefined

        return item;
      });

    this.setState({
      items4: items,
      itemsRamdom4: this._shuffle(items),
      initialitems4: items
    });
  }

  _shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1));
      x = a[i];
      a[i] = a[j];
      a[j] = x;
    }
    return a;
  }

  _checkOrder() {
    if (
      this.state.solved1 ||
      this.state.solved2 ||
      this.state.solved3 ||
      this.state.solved4
    ) {
      this.props.step(`forward`, true)
    }

    if (
      this.state.checked1 ||
      this.state.checked2 ||
      this.state.checked3 ||
      this.state.checked4
    ) {
      this._solve();
      return;
    }
    let items1 = this.state.items1;
    for (let i = 0; i < this.state.items1.length; i++) {
      if (this.state.items1[i].order1 !== i) {
        items1[i].right1 = false;
      } else {
        items1[i].right1 = true;
      }
    }

    let items2 = this.state.items2;
    for (let i = 0; i < this.state.items2.length; i++) {
      if (this.state.items2[i].order2 !== i) {
        items2[i].right2 = false;
      } else {
        items2[i].right2 = true;
      }
    }

    let items3 = this.state.items3;
    for (let i = 0; i < this.state.items3.length; i++) {
      if (this.state.items3[i].order3 !== i) {
        items3[i].right3 = false;
      } else {
        items3[i].right3 = true;
      }
    }

    let items4 = this.state.items4;
    for (let i = 0; i < this.state.items4.length; i++) {
      if (this.state.items4[i].order4 !== i) {
        items4[i].right4 = false;
      } else {
        items4[i].right4 = true;
      }
    }
    this.props.setforceAudio()
    this.setState({
      items1: items1,
      items2: items2,
      items3: items3,
      items4: items4,
      checked1: true,
      checked2: true,
      checked3: true,
      checked4: true,
      buttonMsg: "Solve"
    });
  }

  _solve() {
    let initialitems1 = this.state.initialitems1;
    let initialitems2 = this.state.initialitems2;
    let initialitems3 = this.state.initialitems3;
    let initialitems4 = this.state.initialitems4;

    initialitems1 = initialitems1.map(item => {
      item.right1 = true;
      return item;
    });

    initialitems2 = initialitems2.map(item => {
      item.right2 = true;
      return item;
    });

    initialitems2.sort(function(a, b) {
      return a.order2 - b.order2;
    });

    initialitems3 = initialitems3.map(item => {
      item.right3 = true;
      return item;
    });

    initialitems3.sort(function(a, b) {
      return a.order3 - b.order3;
    });

    initialitems4 = initialitems4.map(item => {
      item.right4 = true;
      return item;
    });

    initialitems4.sort(function(a, b) {
      return a.order4 - b.order4;
    });

    this.setState({
      items1: initialitems1,
      items2: initialitems2,
      items3: initialitems3,
      items4: initialitems4,
      solved1: true,
      solved2: true,
      solved3: true,
      solved4: true,
      buttonMsg: "Done"
    });
  }

  componentDidMount() {
    this._getitems1();
    this._getitems2();
    this._getitems3();
    this._getitems4();
  }

  onSortEnd2 = ({ oldIndex, newIndex }) => {
    this.setState(({ items2 }) => ({
      items2: arrayMove(items2, oldIndex, newIndex)
    }));
  };

  onSortEnd3 = ({ oldIndex, newIndex }) => {
    this.setState(({ items3 }) => ({
      items3: arrayMove(items3, oldIndex, newIndex)
    }));
  };

  onSortEnd4 = ({ oldIndex, newIndex }) => {
    this.setState(({ items4 }) => ({
      items4: arrayMove(items4, oldIndex, newIndex)
    }));
  };

  render() {
    const { blockData, contentIndex } = this.props;
    return (
      <div className="default-card">
        {blockData.content[contentIndex].type === "instruction" ? (
          <div>
            <h3 className="instruction-title">Instruction</h3>
            <h5 className="instruction-text">
              {blockData.content[contentIndex].instruction}
            </h5>
          </div>
        ) : (
          <div className="illustrated-container" style={{}}>
            <div
              className="listen-drag-check-maze"
              onClick={() => {
                this._checkOrder();
              }}
            >
              {this.state.buttonMsg}
            </div>
            <div
              className="row"
              style={{ position: "fixed", paddingLeft: "40px" }}
            >
              <div className="col-md-12">
                <h4>Drag vertically to put in order.</h4>
                <br />
              </div>
            </div>
            <div className="illustrated-words-container drag-drop-list row">
              <div className="col-md-3">
                <SortableList1
                  items1={this.state.items1}
                />
              </div>
              <div className="col-md-3">
                <SortableList2
                  items2={this.state.items2}
                  onSortEnd={this.onSortEnd2}
                />
              </div>
              <div className="col-md-3">
                <SortableList3
                  items3={this.state.items3}
                  onSortEnd={this.onSortEnd3}
                />
              </div>
              <div className="col-md-3">
                <SortableList4
                  items4={this.state.items4}
                  onSortEnd={this.onSortEnd4}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Maze;
