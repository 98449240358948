import React, { Component } from "react";
import '../assets/templates/WorkBook.css';
import lessonEnd from '../assets/images/lesson-end.png'

class Workbook extends Component {
  constructor(props) {
    super(props)

    this.state = {
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="default-card workbook-container">
        <div>
            <img className="workbook-img" alt="LessonEnd" src={lessonEnd} />
        </div>
        <div style={{ marginTop: 15}}>
            <h2> Muito Bem! </h2>
        </div>
        <div style={{ fontWeight: 'lighter', width: '50%', marginTop: 10, marginBottom: 15}}>
            Vamos começar uma revisão, clique no botão abaixo para iniciar. O login será necessário para identificar seu progresso.
        </div>
        <div className="workbook-button" onClick={() => {window.open('https://me.wizard.com.br','_blank')}}>
          Acessar online exercises
        </div>
      </div>
    )
  }
}

export default Workbook;
